import { Connector } from "wagmi";
import Everpay from "everpay";
import * as Sentry from "@sentry/react";
import { setStorage } from "utils/storage";

export class EverPayConnector extends Connector {
  id = "EverPay Wallet";
  name = "EverPay Wallet";
  ready = true;

  provider;

  constructor(config) {
    super(config);
  }

  async connect() {
    const provider = await this.getProvider();

    const data = await provider.smartAccountAuth(
      "https://storage.googleapis.com/metadesk-prod/icon/logo-1708671506.png"
    );

    const emailEverId = provider.genEverId(data.account);

    const account = emailEverId;
    return {
      account,
      provider: provider,
    };
  }
  async getProvider() {
    try {
      if (!this.provider) {
        this.provider = new Everpay();
      }
      return this.provider;
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async disconnect() {
    if (!this.provider) return;
  }

  async isAuthorized() {
    return false;
  }
}
