import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FORM_FETCH_BEGIN,
  FORM_FETCH_SUCCESS,
  FORM_FETCH_FAILURE,
} from "./constants";
import { enqueueSnackbar } from "features/common/redux/actions";
import * as Sentry from "@sentry/react";
import { apiUrl } from "../../configure";
import axios from "axios";
import _ from "lodash";

export function fetchFormData({ formId }) {
  return (dispatch, getState) => {
    dispatch({
      type: FORM_FETCH_BEGIN,
      data: { formId },
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(apiUrl + "api/forms/" + formId);
        const data = result.data;
        const form = {
          formId: formId,
          header: data.header,
          reward: data.reward,
          footer: data.footer,
          startDate: data.startDate,
          endDate: data.endDate,
          restrictions: _.get(data, "restrictions", []),
          responseLimit: data.responseLimit,
          chainId: data.chainId,
          restrictionLogic: data.restrictionLogic,
          isValidDate: data.isValidDate,
          blockchain: data.blockchain,
        };

        if (form.restrictions.length > 0) {
          form.isRestrictionsRequired = true;
        }
        if (data.reward) {
          _.set(form, "reward.hasReward", true);
        }
        if (
          _.get(data, "reward.distributionCriteria", []).length > 0 ||
          _.get(data, "reward.distributionRestritions.requiredTxnCount", "") ||
          _.get(data, "reward.distributionRestritions.requiredDays", "")
        ) {
          _.set(form, "reward.hasDistributionCriteria", true);
          _.set(data, "reward.hasDistributionCriteria", true);
        }

        const questions = _.get(data, "questions", []);
        for (let i = 0; i < questions.length; i++) {
          const q = questions[i];
          if (q.options && q.options[q.options.length - 1].isOther)
            _.set(questions, `[${i}].hasOther`, true);
          if (_.get(q, "discordValidation.roles", []).length > 0)
            _.set(questions, `[${i}].discordValidation.bindingRole`, true);
        }
        const output = {
          form,
          questions,
          reward: data.reward,
          data,
          formId,
          error: data.error,
        };
        dispatch({
          type: FORM_FETCH_SUCCESS,
          data: output,
        });
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
        dispatch({
          type: FORM_FETCH_FAILURE,
        });
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useFetchFormData() {
  const dispatch = useDispatch();

  const { data, fetchFormDataPending } = useSelector(
    (state) => ({
      data: state.form.data,
      fetchFormDataPending: state.form.fetchFormDataPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (dataInput) => {
      return dispatch(fetchFormData(dataInput));
    },
    [dispatch]
  );

  return {
    data,
    fetchFormData: boundAction,
    fetchFormDataPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FORM_FETCH_BEGIN:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.formId]: {
            form: {},
            questions: [],
            data: {},
            error: "",
          },
        },
        fetchFormDataPending: true,
      };

    case FORM_FETCH_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.data.formId]: action.data },
        fetchFormDataPending: false,
      };

    case FORM_FETCH_FAILURE:
      return {
        ...state,
        fetchFormDataPending: false,
      };

    default:
      return state;
  }
}
