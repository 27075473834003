import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import { useConnectWallet, useUserSelect } from "features/home/redux/hooks";
import { Grid, Container, Modal, IconButton } from "@material-ui/core";
import LandingHeader from "features/home/components/LandingHeader";
import { Navigate } from "react-router-dom";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import { useDisconnect } from "wagmi";
const useStyles = makeStyles((theme) => ({
  card: {
    color: "white",
    background: "linear-gradient(89.34deg, #D16FFF -13.43%, #459BFF 98.87%)",
    padding: 25,
    borderRadius: 20,
    margin: 10,
    textAlign: "center",
  },
}));

const CreatorLogin = () => {
  const classes = useStyles();
  const { auth } = useConnectWallet();
  const { user, userSelect } = useUserSelect();

  useEffect(() => {
    if (auth && user !== "creator") userSelect("creator");
  }, [auth]);

  if (auth && user == "creator") {
    return <Navigate to="/creator" />;
  }
  return (
    <>
      <LandingHeader />
      <div
        style={{
          background:
            "linear-gradient(31.48deg, #2C2C2C 15.24%, #383538 88.85%)",
          minHeight: "calc(100vh - 60px)",
          marginTop: 60,
        }}
      >
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          style={{ paddingTop: 5 }}
        >
          <Grid item xs={12}>
            <div className={classes.card}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    position: "relative",
                    textAlign: "left",
                    padding: 20,
                  }}
                >
                  <h1>
                    <img
                      src={require("assets/img/whiteIcon.png")}
                      className="lgIcon"
                      style={{ marginRight: 10 }}
                    />
                    Creator
                  </h1>
                  <div style={{ position: "absolute", bottom: "30px" }}>
                    <ConnectWallet
                      chooseUser="creator"
                      buttonImg={require("assets/img/creatorConnect.png")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <img
                    src={require("assets/img/workspace.png")}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.card}>
              {/* <div style={{ fontSize: 23, textAlign: "left" }}>
                Survey-to-earn
              </div> */}
              <div style={{ textAlign: "center" }}>
                {" "}
                <div
                  style={{ fontSize: 28, margin: "25px 0", fontWeight: 700 }}
                >
                  Cross-Chain Survey-To-Earn
                </div>
                <img
                  style={{ width: "93%", objectFit: "contain" }}
                  src={require("assets/img/landing_userAnalysis.svg").default}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.card}>
              {/* <div style={{ fontSize: 23, textAlign: "left" }}>
                Incentivized
              </div> */}
              <div
                style={{
                  fontSize: 28,
                  margin: "25px 0",
                  fontWeight: 700,
                }}
              >
                A Crypto-Focused
                <br />
                Survey Tool
              </div>
              <div
                className="flex-container"
                style={{
                  justifyContent: "center",
                  flexWrap: "nowrap",
                }}
              >
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src={require("assets/img/landing_restriction.svg").default}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.card}>
              {/* <div style={{ fontSize: 23, textAlign: "left" }}>Seamless</div> */}
              <div
                style={{
                  fontSize: 28,
                  margin: "15px 0",
                  fontWeight: 700,
                }}
              >
                Blockchain-Native
                <br />
                On-chain analytics
              </div>
              <div>On-chain analytics made easy with MetaForm</div>
              <div
                className="flex-container"
                style={{
                  justifyContent: "center",
                  flexWrap: "nowrap",
                }}
              >
                <img
                  style={{ width: "95%", objectFit: "cover" }}
                  src={require("assets/img/landing_surveyToEarn.svg").default}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CreatorLogin;
