import { styled } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledDateTimePickerContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({}) => {
  return {
    display: "flex",
    border: "1px solid #D6D6D6",
    borderRadius: "3px",
    padding: "2px 4px",
    margin: "5px 0",
    "&:focus-within": {
      border: "2px solid rgba(1,182,245,1)",
    },
    "& input": {
      border: 0,
      fontSize: "12px",
      paddingLeft: "20px",
      paddingRight: "5px",
      textAlign: "left",
      "&:focus": {
        border: 0,
        boxShadow: "none",
        paddingLeft: "20px",
        paddingRight: "5px",
      },
    },
    "& span": {
      left: 0,
    },
  };
});
