import { styled } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";

const colorMap = {
  success: { backgroundColor: "#CBE6FF", color: "#4655D3" },
  error: { backgroundColor: "#FFDED7", color: "#FC5555" },
};

export const StyledStatusLabel = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ size = "small", type = "success" }) => {
  return {
    ...colorMap[type],
    ...(size === "small" && {
      fontSize: "12px",
      padding: "1px 5px",
      borderRadius: "15px",
    }),
  };
});
