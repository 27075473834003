import React from "react";
import { Grid, TextField, InputAdornment, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import { Link, useParams } from "react-router-dom";
import { shareUrl } from "features/configure";
import { useUserSelect } from "features/home/redux/hooks";
const useStyles = makeStyles((theme) => ({
  share: {
    border: "1px solid #383538",

    borderRadius: 5,

    "& .MuiInputBase-root": {
      color: "#3CA1FF",
      fontWeight: 700,
      fontSize: 13,
      paddingRight: 0,
    },
  },
}));

const Share = ({ link, hideBackToList }) => {
  const { formId } = useParams();
  const classes = useStyles();

  return (
    <div style={{ background: "white" }}>
      <div style={{ maxWidth: 600, margin: "60px auto", padding: 15 }}>
        <div style={{ margin: "10px 0", fontWeight: 900, fontSize: 22 }}>
          Get the link to share!
        </div>
        <TextField
          classes={{
            root: classes.share,
          }}
          size="small"
          fullWidth
          defaultValue={link || `${shareUrl}${formId}`}
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  style={{ margin: 0, height: 37, background: "#459BFF" }}
                  size="sm"
                  onClick={() => {
                    if (!navigator.clipboard) {
                      return;
                    }
                    navigator.clipboard
                      .writeText(link || `${shareUrl}${formId}`)
                      .then(() => {
                        alert("Copied");
                      })
                      .catch((err) => {
                        // console.log("Something went wrong", err);
                      });
                  }}
                >
                  <img
                    src={require("assets/img/copy.svg").default}
                    className="smIcon"
                  />
                  Copy Link
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {hideBackToList ? (
        ""
      ) : (
        <div style={{ textAlign: "center" }}>
          <Link to={"/creator"}>
            <Button color="primary">Back to List</Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Share;
