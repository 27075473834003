import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  CREATOR_RAW_RESPONSE_FETCH_BEGIN,
  CREATOR_RAW_RESPONSE_FETCH_SUCCESS,
  CREATOR_RAW_RESPONSE_FETCH_FAILURE,
} from "./constants";
import { apiUrl } from "../../configure";
import axios from "axios";
import * as Sentry from "@sentry/react";

import _ from "lodash";
axios.defaults.withCredentials = true;

export function fetchCreatorRawResponseData({
  formId,
  page,
  pageSize,
  filterZero = 0,
  dump = 0,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATOR_RAW_RESPONSE_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(
          apiUrl +
            `api/forms/${formId}/responses/raw?page=${
              page + 1
            }&limit=${pageSize}&dump=${dump}&filterZero=${filterZero}`
        );
        let output = result.data;

        dispatch({
          type: CREATOR_RAW_RESPONSE_FETCH_SUCCESS,
          data: output,
          formId,
        });
      } catch (err) {
        dispatch({
          type: CREATOR_RAW_RESPONSE_FETCH_FAILURE,
        });
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useFetchCreatorRawResponseData() {
  const dispatch = useDispatch();

  const { creatorRawResponseData, fetchCreatorRawResponseDataPending } =
    useSelector(
      (state) => ({
        creatorRawResponseData: state.form.creatorRawResponseData,
        fetchCreatorRawResponseDataPending:
          state.form.fetchCreatorRawResponseDataPending,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchCreatorRawResponseData(data));
    },
    [dispatch]
  );

  return {
    creatorRawResponseData,
    fetchCreatorRawResponseData: boundAction,
    fetchCreatorRawResponseDataPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATOR_RAW_RESPONSE_FETCH_BEGIN:
      return {
        ...state,
        fetchCreatorRawResponseDataPending: true,
      };

    case CREATOR_RAW_RESPONSE_FETCH_SUCCESS:
      return {
        ...state,
        creatorRawResponseData: {
          ...state.creatorRawResponseData,
          [action.formId]: action.data,
        },
        fetchCreatorRawResponseDataPending: false,
      };

    case CREATOR_RAW_RESPONSE_FETCH_FAILURE:
      return {
        ...state,
        fetchCreatorRawResponseDataPending: false,
      };

    default:
      return state;
  }
}
