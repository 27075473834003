import React, { useEffect, useLayoutEffect } from "react";
import { Provider } from "react-redux";
import { RouterProvider, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import router from "./route";
import ColorModeContext from "@metacrm/metacrm-material-ui/dist/contexts/ColorMode.context";
import { hot, setConfig } from "react-hot-loader";
import store from "./common/store";
import { SnackbarProvider } from "notistack";
import { Helmet } from "react-helmet";
import {
  arbitrum,
  mainnet,
  polygon,
  optimism,
  bsc,
  okc,
} from "@wagmi/core/chains";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import useColorMode from "@metacrm/metacrm-material-ui/dist/hooks/useColorMode.hooks";
import { QubicConnector } from "connectors/QubicConnector";
import { EverPayConnector } from "connectors/EverPayConnector";

import * as Sentry from "@sentry/react";
import {
  WalletProvider,
  MartianWalletAdapter,
  AptosWalletAdapter,
  RiseWalletAdapter,
  BloctoWalletAdapter,
} from "@manahippo/aptos-wallet-adapter";

import { JoyIdConnector } from "@joyid/wagmi";

const wallets = [
  new MartianWalletAdapter(),
  new RiseWalletAdapter(),
  new AptosWalletAdapter(),
  new BloctoWalletAdapter({
    bloctoAppId: "22e8e88f-d367-4ea1-af6c-14d1fb765fe0",
  }),
];

Sentry.init({
  dsn: "https://fa2f1859d2dc46ea8456fe9de563e975@o4504931933093888.ingest.sentry.io/4504931940827136",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
});

const projectId = "14950761bae30fa408a44df6a0576d7c"; //walletConnet project ID

const chains = [mainnet, polygon, optimism, arbitrum, bsc, okc];
const { publicClient, webSocketPublicClient } = configureChains(chains, [
  alchemyProvider({
    apiKey: "eg4gCkiDZxQ1X_D4vDk0Mz30lDwWQyVm",
    priority: 0,
  }),
  publicProvider(),
]);

const connectors = [
  new InjectedConnector({
    chains,
    options: {
      name: "Browser Wallet",
      shimDisconnect: true,
    },
  }),
  new MetaMaskConnector({
    chains,
    options: {
      name: "MetaMask",
      shimDisconnect: true,
      UNSTABLE_shimOnConnectSelectAccount: true,
    },
  }),

  new WalletConnectConnector({
    chains,
    options: {
      projectId,
    },
  }),
  new InjectedConnector({
    chains,
    options: {
      name: "BinanceWallet",
      shimDisconnect: true,
      UNSTABLE_shimOnConnectSelectAccount: true,
      getProvider: () =>
        typeof window !== "undefined" ? window.BinanceChain : undefined,
    },
  }),
  new QubicConnector({
    chains,
    options: {
      name: "Qubic Wallet",
    },
  }),
  new EverPayConnector({
    chains,
    options: {
      name: "EverPay Wallet",
    },
  }),
  new JoyIdConnector({
    chains: [polygon],
    options: {
      // name of your app
      name: "MetaForm",
      // logo of your app
      logo: "https://metaform.xyz/static/media/metaformHeader.b42afdae.svg",
      // JoyID app url that your app is integrated with
      joyidAppURL: "https://app.joy.id",
    },
  }),
];

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

setConfig({
  logLevel: "debug",
});

const renderMetadata = (
  title = "MetaForm",
  desc = "MetaForm",
  url,
  imageURL
) => {
  if (!url) {
    url = window.location.href;
  }
  if (!imageURL) {
    imageURL = window.location.origin + require("assets/img/defaultBanner.png");
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="fragment" content="!" />
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={desc} />
      <meta itemprop="image" content={imageURL} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={imageURL} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={imageURL} />
      <meta name="description" content={desc} />
      <script
        src="https://attribution.metacrm.inc/tracking-1-0-2.js"
        data-entity-id="6582d1296732228f1faa12a7"
        id="metacrm-tracking"
        integrity="sha384-Wk8jkbM+OJTMrStnB9kz3nfAH8y081x4KGPoK2delfBvw6+MSH3bE1mZ4sf90SHo"
        crossorigin="anonymous"
      ></script>
    </Helmet>
  );
};

function Root() {
  const { colorModeHooksValue, theme } = useColorMode();

  // initializePriceCache()
  return (
    <GTMProvider state={{ id: "GTM-MZRF5RKT" }}>
      <Provider store={store}>
        {renderMetadata("MetaForm", "", window.location.href, null)}
        <Sentry.ErrorBoundary
          fallback={
            <div className="loadingPaper">
              <h1>Oops! Somthing Wrong!</h1>
            </div>
          }
          showDialog
        >
          <ColorModeContext.Provider value={colorModeHooksValue}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider>
                <WagmiConfig config={wagmiConfig}>
                  <WalletProvider
                    wallets={wallets}
                    autoConnect={
                      false
                    } /** allow auto wallet connection or not **/
                    onError={(error) => {
                      console.log("Handle Error Message", error);
                    }}
                  >
                    <RouterProvider router={router} />
                  </WalletProvider>
                </WagmiConfig>
              </SnackbarProvider>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </Sentry.ErrorBoundary>
      </Provider>
    </GTMProvider>
  );
}

export default hot(module)(Root);
