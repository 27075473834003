import { useCallback } from "react";
import { erc20ABI } from "../../configure";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_TOKEN_APPROVAL_BEGIN,
  FETCH_TOKEN_APPROVAL_SUCCESS,
  FETCH_TOKEN_APPROVAL_FAILURE,
} from "./constants";
import { enqueueSnackbar } from "../../common/redux/actions";
import { ethers } from "ethers";
import { writeContract } from "@wagmi/core";
import _ from "lodash";
export function fetchApproval({ tokenAddress, contractAddress, amount }) {
  return async (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_TOKEN_APPROVAL_BEGIN,
    });

    try {
      const { hash } = await writeContract({
        address: tokenAddress,
        abi: erc20ABI,
        functionName: "approve",
        args: [contractAddress, amount],
      });

      dispatch(
        enqueueSnackbar({
          message: hash,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
          hash,
        })
      );
    } catch (error) {
      dispatch({ type: FETCH_TOKEN_APPROVAL_FAILURE });
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "reason", "Error"),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
    }
  };
}

export function useFetchApproval() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { fetchApprovalPending } = useSelector((state) => ({
    fetchApprovalPending: state.form.fetchApprovalPending,
  }));

  const boundAction = useCallback(
    (data) => dispatch(fetchApproval(data)),
    [dispatch]
  );

  return {
    fetchApproval: boundAction,
    fetchApprovalPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_TOKEN_APPROVAL_BEGIN:
      return {
        ...state,
        fetchApprovalPending: true,
      };

    case FETCH_TOKEN_APPROVAL_SUCCESS:
    case FETCH_TOKEN_APPROVAL_FAILURE:
      return {
        ...state,
        fetchApprovalPending: false,
      };

    default:
      return state;
  }
}
