export const FORM_FETCH_BEGIN = "FORM_FETCH_BEGIN";
export const FORM_FETCH_SUCCESS = "FORM_FETCH_SUCCESS";
export const FORM_FETCH_FAILURE = "FORM_FETCH_FAILURE";

export const SURVEY_LIST_FETCH_BEGIN = "SURVEY_LIST_FETCH_BEGIN";
export const SURVEY_LIST_FETCH_SUCCESS = "SURVEY_LIST_FETCH_SUCCESS";
export const SURVEY_LIST_FETCH_FAILURE = "SURVEY_LIST_FETCH_FAILURE";

export const RESPONSE_FETCH_BEGIN = "RESPONSE_FETCH_BEGIN";
export const RESPONSE_FETCH_SUCCESS = "RESPONSE_FETCH_SUCCESS";
export const RESPONSE_FETCH_FAILURE = "RESPONSE_FETCH_FAILURE";

export const CREAT_LIST_FETCH_BEGIN = "CREAT_LIST_FETCH_BEGIN";
export const CREAT_LIST_FETCH_SUCCESS = "CREAT_LIST_FETCH_SUCCESS";
export const CREAT_LIST_FETCH_FAILURE = "CREAT_LIST_FETCH_FAILURE";

export const CREATOR_RESPONSE_FETCH_BEGIN = "CREATOR_RESPONSE_BEGIN";
export const CREATOR_RESPONSE_FETCH_SUCCESS = "CREATOR_RESPONSE_FETCH_SUCCESS";
export const CREATOR_RESPONSE_FETCH_FAILURE = "CREATOR_RESPONSE_FETCH_FAILURE";

export const CREATOR_RAW_RESPONSE_FETCH_BEGIN = "CREATOR_RAW_RESPONSE_BEGIN";
export const CREATOR_RAW_RESPONSE_FETCH_SUCCESS =
  "CREATOR_RAW_RESPONSE_FETCH_SUCCESS";
export const CREATOR_RAW_RESPONSE_FETCH_FAILURE =
  "CREATOR_RAW_RESPONSE_FETCH_FAILURE";

export const CREATOR_QUESTION_RESPONSE_FETCH_BEGIN =
  "CREATOR_QUESTION_RESPONSE_BEGIN";
export const CREATOR_QUESTION_RESPONSE_FETCH_SUCCESS =
  "CREATOR_QUESTION_RESPONSE_FETCH_SUCCESS";
export const CREATOR_QUESTION_RESPONSE_FETCH_FAILURE =
  "CREATOR_QUESTION_RESPONSE_FETCH_FAILURE";

export const RESPONSE_LIST_FETCH_BEGIN = "RESPONSE_LIST_FETCH_BEGIN";
export const RESPONSE_LIST_FETCH_SUCCESS = "RESPONSE_LIST_FETCH_SUCCESS";
export const RESPONSE_LIST_FETCH_FAILURE = "RESPONSE_LIST_FETCH_FAILURE";

export const CREATE_FORM_BEGIN = "CREATE_FORM_BEGIN";
export const CREATE_FORM_SUCCESS = "CREATE_FORM_SUCCESS";
export const CREATE_FORM_FAILURE = "CREATE_FORM_FAILURE";

export const CREATE_RESPONSE_BEGIN = "CREATE_RESPONSE_BEGIN";
export const CREATE_RESPONSE_SUCCESS = "CREATE_RESPONSE_SUCCESS";
export const CREATE_RESPONSE_PROGRESS = "CREATE_RESPONSE_PROGRESS";
export const CREATE_RESPONSE_FAILURE = "CREATE_RESPONSE_FAILURE";

export const FETCH_WRAP_BEGIN = "FETCH_WRAP_BEGIN";
export const FETCH_WRAP_SUCCESS = "FETCH_WRAP_SUCCESS";
export const FETCH_WRAP_FAILURE = "FETCH_WRAP_FAILURE";

export const UPDATE_FORM_BEGIN = "UPDATE_FORM_BEGIN";
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS";
export const UPDATE_FORM_FAILURE = "UPDATE_FORM_FAILURE";

export const UPDATE_REWARD_BEGIN = "UPDATE_REWARD_BEGIN";
export const UPDATE_REWARD_SUCCESS = "UPDATE_REWARD_SUCCESS";
export const UPDATE_REWARD_FAILURE = "UPDATE_REWARD_FAILURE";

export const FETCH_TOKEN_APPROVAL_BEGIN = "FETCH_TOKEN_APPROVAL_BEGIN";
export const FETCH_TOKEN_APPROVAL_SUCCESS = "FETCH_TOKEN_APPROVAL_SUCCESS";
export const FETCH_TOKEN_APPROVAL_FAILURE = "FETCH_TOKEN_APPROVAL_FAILURE";

export const FETCH_NFT_APPROVAL_BEGIN = "FETCH_NFT_APPROVAL_BEGIN";
export const FETCH_NFT_APPROVAL_SUCCESS = "FETCH_NFT_APPROVAL_SUCCESS";
export const FETCH_NFT_APPROVAL_FAILURE = "FETCH_NFT_APPROVAL_FAILURE";

export const FETCH_DISTRIBUTE_BEGIN = "FETCH_DISTRIBUTE_BEGIN";
export const FETCH_DISTRIBUTE_SUCCESS = "FETCH_DISTRIBUTE_SUCCESS";
export const FETCH_DISTRIBUTE_FAILURE = "FETCH_DISTRIBUTE_FAILURE";

export const UPDATE_FORM_ACCESS_BEGIN = "UPDATE_FORM_ACCESS_BEGIN";
export const UPDATE_FORM_ACCESS_SUCCESS = "UPDATE_FORM_ACCESS_SUCCESS";
export const UPDATE_FORM_ACCESS_FAILURE = "UPDATE_FORM_ACCESS_FAILURE";

export const FETCH_FORM_ACCESS_BEGIN = "FETCH_FORM_ACCESS_BEGIN";
export const FETCH_FORM_ACCESS_SUCCESS = "FETCH_FORM_ACCESS_SUCCESS";
export const FETCH_FORM_ACCESS_FAILURE = "FETCH_FORM_ACCESS_FAILURE";
