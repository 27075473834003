import React from "react";
import { Grid, TextField, InputAdornment, Divider } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  container: { background: "white" },
}));
const mapping = {
  left: { padding: 20, reverse: true, imgMaxHeight: 300 },
  leftCover: { reverse: true },
  right: { padding: 20, imgMaxHeight: 300 },
  rightCover: {},
  center: { padding: 20, imgMaxHeight: 300 },
  background: {},
};
const Layout = ({
  type = "center",
  text,
  imgUrl,
  isCard,
  renderImageUpload,
  source,
  header,
  upside,
  onImageLoad,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const renderImg = () => {
    return renderImageUpload
      ? renderImageUpload(
          source,
          imgUrl && (
            <>
              <img
                src={imgUrl}
                onLoad={(e) => onImageLoad(e)}
                style={{
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: m.imgMaxHeight || "calc(100vh - 125px)",
                }}
              />
            </>
          )
        )
      : imgUrl && (
          <img
            src={imgUrl}
            style={{
              width: "100%",
              objectFit: "contain",
              maxHeight: m.imgMaxHeight || "calc(100vh - 125px)",
            }}
          />
        );
  };
  if (type == "background" || !imgUrl) {
    return (
      <>
        <div
          className={classes.container}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: isCard ? 600 : "calc(100vh - 92px)",
            padding: 0,
            backgroundImage: `url(${imgUrl})`,
            backgroundSize: "cover",
            position: "relative",
            backgroundPosition: "center",
          }}
        >
          <div
            style={{
              minWidth: 300,
              margin: "0 auto",
              padding: "80px 20px 0px 20px",
              maxWidth: 550,
            }}
          >
            {text}
          </div>
          <div
            style={{
              textAlign: "center",
              position: computer ? "relative" : "absolute",
              bottom: 0,
            }}
          >
            {" "}
            {header}
          </div>
        </div>
      </>
    );
  }

  const m = _.get(mapping, `[${type}]`, mapping["right"]);
  return (
    <div
      className={classes.container}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: isCard ? 600 : "calc(100vh - 100px)",
        maxWidth: isCard ? "" : 1100,
        margin: "0 auto",
        padding: 0,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ padding: m.padding || 0 }}
        direction={m.reverse ? "row-reverse" : "row"}
        spacing={0}
      >
        <Grid
          item
          xs={12}
          sm={type == "center" ? 12 : 6}
          style={{
            padding: 20,
          }}
        >
          {upside ? renderImg() : text}
        </Grid>
        <Grid item xs={12} sm={type == "center" ? 12 : 6}>
          {upside ? text : renderImg()}
        </Grid>
        <Grid
          style={{
            position: computer ? "relative" : "absolute",
            bottom: computer ? "" : 108,
            // zIndex: 1000,
          }}
        >
          {header}
        </Grid>
      </Grid>
    </div>
  );
};

export default Layout;
