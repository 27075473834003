import styled from "styled-components";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/Tabs";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

export const StyledTabs = styled(CustomTabs)`
  border-radius: 0;
`;

export const StyledEditButton = styled(Button)`
  font-size: 12px;
  min-width: unset;
  padding: 5px;
  & img {
    margin-right: 3px;
  }
`;

export const StyledRestrictText = styled.div`
  font-size: 13px;
  color: #555555;
`;

export const StyledDataSourceDropDown = styled(CustomDropdown)`
  & .MuiSelect-root {
    background-color: #f1f1f1;
    border: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  & .MuiSelect-icon {
    right: -5px;
  }
`;
