const styles = (theme) => ({
  title: {
    fontSize: 12,
    fontWeight: 500,
    padding: "0px 3px",

    // "&:first-child": {
    //   borderTopLeftRadius: 10,
    //   borderBottomLeftRadius: 10,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 10,
    //   borderBottomRightRadius: 10,
    // },
  },
  tableTitle: {
    backgroundColor: "transparent",
    borderBottom: `0px solid ${theme.palette.text.primary}`,
    fontWeight: 500,
    fontSize: 13,
    color: "#383538",
  },
  outlinedTitle: {
    fontSize: 15,
    backgroundColor: "#ffffff",
    color: "#0096FF",
    borderRight: `0.7px solid rgba(56, 56, 56, 0.2)`,
    borderBottom: `2px solid #0096FF`,
  },

  columnTitle: {
    borderBottom: `0px solid ${theme.palette.text.primary}`,
    borderRight: `0px solid ${theme.palette.text.primary}`,
  },
  value: {
    fontSize: 12,
    fontWeight: 600,
    height: 60,
    color: "#383538",
    backgroundColor: "#FFFFFF",
    // "&:first-child": {
    //   borderTopLeftRadius: 10,
    //   borderBottomLeftRadius: 10,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 10,
    //   borderBottomRightRadius: 10,
    // },
  },
  tableRow: {
    "&:hover": {
      // border: "1px solid blue !important",
    },
    // "&:first-child": {
    //   borderTopLeftRadius: 10,
    //   borderBottomLeftRadius: 10,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 10,
    //   borderBottomRightRadius: 10,
    // },
  },

  card: {
    flexGrow: 1,
    backgroundColor: "transparent",
    overflow: "auto",
    marginTop: 10,
  },
  tableValue: {
    padding: "3px",
    backgroundColor: "#ffffff",
    border: 0,
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    // borderTop: "1px solid rgba(165, 165, 165, 0.5)",
    // borderBottom: "1px solid rgba(165, 165, 165, 0.5)",
    "&:first-child": {
      borderRadius: "5px 0 0 5px",
    },
    "&:last-child": {
      borderRadius: "0 5px 5px 0 ",
    },
  },
  outlinedValue: {
    border: `0.7px solid rgba(56, 56, 56, 0.2)`,
    padding: 5,
    "&:first-child": {
      borderLeft: "0px",
    },
    // "&:last-child": {
    //   borderRight: "0px",
    // },
  },
  columnValue: {
    borderRight: `0px solid ${theme.palette.text.primary}`,
    borderBottom: "none",
  },
  selected: {
    backgroundColor: "rgba(0, 150, 255, 0.19)",
  },
  icon: {
    opacity: 0.6,
  },
});

export default styles;
