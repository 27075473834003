import React, { useEffect, useState } from "react";
import { Grid, Checkbox, Radio, IconButton } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withStyles } from "@material-ui/core/styles";
import ImageUpload from "../components/ImageUpload";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import _ from "lodash";

const Choice = ({ questions, index, setValue, answers, checkAgain }) => {
  const setQuestionData = (index, label, value) => {
    let newData = [...(answers || questions)];
    _.set(newData, `[${index}].${label}`, value);
    setValue(newData);
  };

  const setQuestionOptionData = (index, i, label, value) => {
    let newData = [...questions];
    _.set(newData, `[${index}].options[${i}].${label}`, value);
    setValue(newData);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...Array.from(list)];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const createQuestionOption = (index, isOther) => {
    let newOption = {
      title: "",
    };
    if (isOther) {
      newOption.isOther = true;
    }
    let newData = [...questions];
    if (newData[index].hasOther) {
      newData[index].options.splice(
        newData[index].options.length - 1,
        0,
        newOption
      );
    } else {
      newData[index].options.push(newOption);
    }

    setValue(newData);
  };

  const removeQuestionOptionData = (index, i) => {
    if (questions[index].options.length == 2) return;
    if (questions[index].options[i].isOther) {
      setQuestionData(index, "hasOther", false);
    }
    let newData = [...questions];
    newData[index].options.splice(i, 1);
    setValue(newData);
  };

  const q = questions[index];
  if (!answers)
    return (
      <div style={{ marginTop: 15, position: "relative" }}>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }
            let source = result.source.index;
            let destination = result.destination.index;

            const items = reorder(q.options, source, destination);

            let newData = [...questions];
            _.set(newData, `[${index}].options`, items);
            setValue(newData);
          }}
        >
          <Droppable droppableId={"droppableOption" + index}>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {q.options &&
                  q.options.map((option, i) => {
                    const optionImg = _.get(option, "img", []);
                    return (
                      <Draggable
                        key={"q" + index + "option" + i}
                        draggableId={"q" + index + "option" + i}
                        index={i}
                        isDragDisabled={option.isOther}
                      >
                        {(provided, snapshot) => {
                          if (snapshot.isDragging) {
                            provided.draggableProps.style = {
                              ...provided.draggableProps.style,
                              left: provided.draggableProps.style.offsetLeft,
                              top: provided.draggableProps.style.offsetTop,
                            };
                          }
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="betweenRow">
                                <div className="startRow">
                                  <span style={{ margin: 3 }}>三</span>
                                  {!q.multiple ? (
                                    <Radio
                                      disabled
                                      style={{ color: "#141314", padding: 0 }}
                                    />
                                  ) : (
                                    <Checkbox
                                      disabled
                                      style={{ color: "#141314", padding: 0 }}
                                    />
                                  )}
                                  <div style={{ marginLeft: 5 }}>
                                    {option.isOther ? (
                                      <CustomOutlinedInput
                                        disabled
                                        option="true"
                                        value={"Other"}
                                      />
                                    ) : (
                                      <CustomOutlinedInput
                                        multiline
                                        option="true"
                                        onBlur={checkAgain}
                                        value={_.get(option, "title", "")}
                                        placeholder={"option " + (i + 1)}
                                        onChange={(e) =>
                                          setQuestionOptionData(
                                            index,
                                            i,
                                            "title",
                                            e.target.value
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="startRow">
                                  <ImageUpload
                                    value={_.get(option, "img", [])}
                                    onChange={(imageList) => {
                                      setQuestionOptionData(
                                        index,
                                        i,
                                        "img",
                                        imageList
                                      );
                                    }}
                                    notShowPreview={true}
                                  />
                                  <IconButton
                                    style={{ marginLeft: 5, padding: 0 }}
                                    onClick={() => {
                                      removeQuestionOptionData(index, i);
                                    }}
                                  >
                                    <img
                                      src={
                                        require("assets/img/cancel.svg").default
                                      }
                                      style={{ width: 20, height: 20 }}
                                    />
                                  </IconButton>
                                </div>
                              </div>

                              {optionImg.length > 0 &&
                                Array.isArray(optionImg) &&
                                optionImg.map((img, imgIndex) => {
                                  return (
                                    <div className="startRow" key={imgIndex}>
                                      <img
                                        src={_.get(img, "data_url", img)}
                                        className="imgPreview"
                                      />{" "}
                                      <IconButton
                                        onClick={() => {
                                          let newData = [...questions];
                                          _.unset(
                                            newData,
                                            `[${index}].options[${i}].img`
                                          );
                                          setValue(newData);
                                        }}
                                        style={{ padding: 0, marginLeft: 10 }}
                                      >
                                        <HighlightOffIcon
                                          style={{
                                            color: "#A5A5A5",
                                            fontSize: 13,
                                          }}
                                        />
                                      </IconButton>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div style={{ textAlign: "left" }}>
          <Button color="text" onClick={() => createQuestionOption(index)}>
            Add Options
          </Button>

          {!q.hasOther && (
            <>
              <span style={{ fontWeight: 700, fontSize: 15 }}>or</span>
              <Button
                color="text"
                onClick={() => {
                  createQuestionOption(index, true);
                  setQuestionData(index, "hasOther", true);
                }}
              >
                Add "Other"
              </Button>
            </>
          )}
        </div>
      </div>
    );

  return (
    <Grid container>
      {q.options.map((option, i) => {
        const hasImage = option.img && option.img.length > 0;
        const answer = option.isOther ? "Other" : option.title;
        const checked = q.multiple
          ? _.find(_.get(answers, `[${index}].value`, []), {
              index: i,
            })
            ? true
            : false
          : _.get(answers, `[${index}].value`) === answer;
        return (
          <Grid item xs={12} md={hasImage ? 6 : 12} key={i}>
            <div
              className="option"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (q.multiple) {
                  let newData = [];
                  if (answers[index] && answers[index].value) {
                    newData = [...answers[index].value];
                  }
                  let hasChecked = _.find(newData, { index: i });
                  if (hasChecked) {
                    // _.pullAt(newData, [dataIndex]);
                    newData = _.pullAllBy(newData, [{ index: i }], "index");
                  } else {
                    newData.push({ value: answer, index: i });
                  }
                  newData = _.sortBy(newData, ["index"]);
                  setQuestionData(index, "value", newData, q);
                } else {
                  setQuestionData(index, "value", answer, q);
                }
              }}
            >
              {hasImage && (
                <div
                  style={{
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                    borderRadius: 5,
                    margin: "10px 0",
                    textAlign: "center",
                    border: checked ? "2px solid #0096FF" : "0px",
                  }}
                >
                  <img
                    src={_.get(option, "img[0].data_url", _.get(option, "img"))}
                    className="imgPreview"
                  />
                </div>
              )}
              <div className="startRow">
                {!q.multiple ? (
                  <Radio style={{ padding: 0 }} checked={checked} />
                ) : (
                  <Checkbox
                    style={{ color: "#383538", padding: 0 }}
                    checked={checked}
                    onChange={(e) => {}}
                  />
                )}
                {option.isOther ? (
                  <div style={{ marginBottom: "0px", marginLeft: 10 }}>
                    <CustomOutlinedInput
                      option
                      style={{ margin: 0, marginLeft: "-5px" }}
                      value={_.get(answers, `[${index}].otherText`, "")}
                      placeholder={"Other"}
                      onChange={(e) =>
                        setQuestionData(index, "otherText", e.target.value, q)
                      }
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: 18,
                      color: "#141314",
                      fontWeight: 900,
                      marginLeft: 10,
                    }}
                  >
                    {option.title}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Choice;
