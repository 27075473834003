import { reducer as fetchFormDataReducer } from "./fetchFormData";
import { reducer as fetchCreateListReducer } from "./fetchCreateList";
import { reducer as fetchResponseDataReducer } from "./fetchResponseData";
import { reducer as fetchCreatorResponseDataReducer } from "./fetchCreatorResponseData";
import { reducer as fetchCreatorRawResponseDataReducer } from "./fetchCreatorRawResponseData";
import { reducer as fetchCreatorQuestionResponseDataReducer } from "./fetchCreatorQuestionResponseData";
import { reducer as fetchSurveyListReducer } from "./fetchSurveyList";
import { reducer as fetchResponseListReducer } from "./fetchResponseList";
import { reducer as createFormReducer } from "./createForm";
import { reducer as createResponseReducer } from "./createResponse";
import { reducer as fetchSwapDetailReducer } from "./fetchSwapDetail";
import { reducer as updateSettingsReducer } from "./updateSettings";
import { reducer as updateRewardReducer } from "./updateReward";
import { reducer as fetchApprovalReducer } from "./fetchApproval";
import { reducer as fetchNFTApprovalReducer } from "./fetchNFTApproval";
import { reducer as fetchDistributeReducer } from "./fetchDistribute";
import { reducer as fetchInviteReducer } from "./fetchInvite";
const reducers = [
  fetchFormDataReducer,
  fetchCreateListReducer,
  fetchResponseDataReducer,
  fetchCreatorRawResponseDataReducer,
  fetchResponseListReducer,
  createFormReducer,
  fetchSurveyListReducer,
  createResponseReducer,
  fetchCreatorResponseDataReducer,
  fetchCreatorQuestionResponseDataReducer,
  fetchSwapDetailReducer,
  updateSettingsReducer,
  updateRewardReducer,
  fetchApprovalReducer,
  fetchDistributeReducer,
  fetchInviteReducer,
];

const initialState = {
  address: "",
  web3: null,
  createList: [],
  data: {},
  surveyList: [],
  invitedList: [],
  responseList: [],
  creatorTotalCount: 0,
  invitedTotalCount: 0,
  connected: false,
  networkId: Number(process.env.REACT_APP_NETWORK_ID),
};

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
