export const HOME_CONNECT_WALLET_BEGIN = "HOME_CONNECT_WALLET_BEGIN";
export const HOME_CONNECT_WALLET_SUCCESS = "HOME_CONNECT_WALLET_SUCCESS";
export const HOME_CONNECT_WALLET_FAILURE = "HOME_CONNECT_WALLET_FAILURE";
export const HOME_ACCOUNTS_CHANGED = "HOME_ACCOUNTS_CHANGED";
export const HOME_NETWORK_CHANGED = "HOME_NETWORK_CHANGED";

export const HOME_DISCONNECT_WALLET_BEGIN = "HOME_DISCONNECT_WALLET_BEGIN";
export const HOME_DISCONNECT_WALLET_SUCCESS = "HOME_DISCONNECT_WALLET_SUCCESS";
export const HOME_DISCONNECT_WALLET_FAILURE = "HOME_DISCONNECT_WALLET_FAILURE";

export const FETCH_TVL_BEGIN = "FETCH_TVL_BEGIN";
export const FETCH_TVL_SUCCESS = "FETCH_TVL_SUCCESS";
export const FETCH_TVL_FAILURE = "FETCH_TVL_FAILURE";

export const POOL_FETCH_BEGIN = "POOL_FETCH_BEGIN";
export const POOL_FETCH_SUCCESS = "POOL_FETCH_SUCCESS";
export const POOL_FETCH_FAILURE = "POOL_FETCH_FAILURE";

export const AUTH_CHECK_BEGIN = "AUTH_CHECK_BEGIN";
export const AUTH_CHECK_SUCCESS = "AUTH_CHECK_SUCCESS";
export const AUTH_CHECK_FAILURE = "AUTH_CHECK_FAILURE";

export const SELECT_USER = "SELECT_USER";
