const styles = (theme) => ({
  button: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: `${theme.palette.text.green}`,
    color: `white`,
    textTransform: "none",
    // border: `1px solid ${theme.palette.text.green}`,
    fontFamily: "Inter",
    borderRadius: "3px",
    position: "relative",
    padding: "10px 30px",
    margin: 3,
    fontSize: "17px",
    letterSpacing: "0",
    // willChange: 'box-shadow, transform',
    // transition:
    //   'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    // touchAction: 'manipulation',
    cursor: "pointer",
    "& .MuiButton-label": {
      // color: `${theme.palette.text.green}`,
      fontWeight: 700,
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "18px",
      height: "18px",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "white",
      },
    },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        marginTop: "0px",
        marginRight: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
      },
    },
  },
  fullWidth: {
    width: "100%",
    margin: 0,
  },
  blueOutlined: {
    backgroundColor: "transparent",
    border: `0.5px solid #0096FF`,
    fontSize: "14px",
    boxShadow: "3px 3px 1px rgba(0, 0, 0, 0.2)",
    height: "39px",
    padding: "10px 15px",
    borderRadius: "5px",
    "& .MuiButton-label": {
      color: `#0096FF`,
    },
    "&:hover": {
      backgroundColor: `transparent`,
      "& .MuiButton-label": {
        color: "#0096FF",
      },
    },
  },
  blue: {
    boxShadow: "5px 5px 5px 1px rgba(0, 0, 0, 0.5)",
    minWidth: 180,
  },

  primary: {
    backgroundColor: "#2D9AEE",
    fontSize: "14px",
    boxShadow: "3px 3px 1px rgba(0, 0, 0, 0.2)",
    marginRight: 10,
    height: "39px",
    padding: "10px 15px",
    borderRadius: "5px",
    "& .MuiButton-label": {
      fontWeight: 700,
      color: "#ffffff",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
      backgroundColor: "#2D9AEE",
    },
  },
  secondary: {
    background: "#FFFFFF",
    margin: 2.5,
    marginRight: 5,
    marginLeft: 0,
    minWidth: 120,
    padding: 10,
    fontSize: "12px",
    borderRadius: "5px",
    border: "1px solid rgba(56, 56, 56, 0.5)",
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    height: "30px",
    fontWeight: 600,
    "& .MuiButton-label": {
      color: `#555555`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#555555",
      },
      background: "#FFFFFF",
    },
  },

  dark: {
    background: "#383538",
    margin: 5,
    minWidth: 92,
    padding: 10,
    fontSize: "16px",
    fontWeight: 900,
    borderRadius: "5px",
    height: "40px",
    "& .MuiButton-label": {
      color: `white`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: "#383538",
    },
  },
  connect: {
    background: "#FFFFFF",
    zIndex: 1000,
    boxShadow: "1px 2.0624px 6.18719px rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    padding: "15px 25px",
    "& .MuiButton-label": {
      color: "#383538",
      fontWeight: 700,
      fontSize: 18,
    },
    height: 48,
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#383538",
      },
      background: "#FFFFFF",
    },
  },
  mobileConnect: {
    background: "#0096FF",
    zIndex: 1000,
    boxShadow: "1px 2.0624px 6.18719px rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    padding: "15px 25px",
    "& .MuiButton-label": {
      color: "white",
      fontWeight: 700,
      fontSize: 14,
    },
    height: 48,
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: "#0096FF",
    },
  },
  login: {
    background: "#555555",
    boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    padding: "12px 25px",
    "& .MuiButton-label": {
      color: "#FFFFFF",
      fontWeight: 700,
      fontSize: 20,
    },
    height: 48,
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#FFFFFF",
      },
      background: "#555555",
    },
  },
  social: {
    background: "transparent",
    minWidth: 0,
    margin: "5px 5px",
    borderRadius: 40,
    padding: "7px 5px",
    boxShadow: "0px 0px 0px",
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "& .MuiButton-label": {
      color: `#ffffff`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
      background: "#0096FF",
    },
  },
  socialDark: {
    background: "transparent",
    minWidth: 0,
    margin: "5px 5px",
    borderRadius: 40,
    padding: "7px 5px",
    boxShadow: "0px 0px 0px",
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "& .MuiButton-label": {
      color: `#141314`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
      background: "#0096FF",
    },
  },

  form: {
    background: "white",
    margin: 0,
    marginBottom: 2.5,
    marginTop: 2.5,
    marginRight: 5,
    minWidth: 40,
    border: "0.5px solid #A5A5A5",
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    borderRadius: 3,
    padding: "3px 5px",
    "& .MuiButton-label": {
      color: `#383538`,
      fontSize: "12px",
      fontWeight: 600,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#383538",
        fontWeight: 600,
      },
      background: "white",
    },
  },

  formPrimary: {
    background: "#0096FF",
    margin: 5,
    minWidth: 40,
    boxShadow: "3px 3px 1px rgba(0, 0, 0, 0.2)",
    borderRadius: 5,
    padding: "3px 5px",
    height: "29px",
    "& .MuiButton-label": {
      color: `#FFFFFF`,
      fontSize: "12px",
      fontWeight: 600,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#FFFFFF",
        fontWeight: 600,
      },
      background: "#0096FF",
    },
  },
  linear: {
    background: "linear-gradient(89.34deg, #D16FFF -13.43%, #459BFF 98.87%)",
    margin: 5,
    minWidth: 140,
    borderRadius: 3,
    padding: 10,
    fontSize: "14px",
    height: "29px",
    "& .MuiButton-label": {
      color: `white`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: "linear-gradient(89.34deg, #D16FFF -13.43%, #459BFF 98.87%)",
    },
  },

  text: {
    backgroundColor: "transparent",
    padding: 0,
    margin: 10,
    height: 20,
    borderRadius: 0,
    "& .MuiButton-label": {
      fontWeight: 500,
      fontSize: 16,
      color: `#383538`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#383538",
      },
      backgroundColor: "transparent",
    },
  },
  darkText: {
    backgroundColor: "transparent",
    margin: 10,
    padding: "6px 12px",
    borderRadius: 20,
    "& .MuiButton-label": {
      fontWeight: 400,
      fontSize: 14,
      color: `#383538`,
      textAlign: "left",
    },
    "&:hover,&:focus": {
      border: "1px solid #459BFF",
      "& .MuiButton-label": {
        color: "#383538",
      },
      backgroundColor: "transparent",
    },
  },
  follow: {
    border: `4px solid #F6F6F6`,
    width: 96,
    height: 45,
    backgroundColor: "#848484",
    fontSize: "1rem",
    fontWeight: 900,
    "& .MuiButton-label": {
      color: "#F6F6F6",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#F6F6F6",
      },
      backgroundColor: "#848484",
    },
  },
  info: {
    backgroundColor: "transparent",
    borderRadius: 20,
    padding: "0px 20px",
    border: `1px solid ${theme.palette.text.primary}`,
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: theme.palette.text.primary,
      },
      backgroundColor: "transparent",
    },
  },
  transparent: {
    background: "transparent",
    padding: 0,
    minWidth: 0,
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none",
    },
  },
  disabled: {
    opacity: "0.65",
    textDecoration: "none",
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    border: "none",
    backgroundColor: "rgb(221, 221, 221)",

    pointerEvents: "none",
  },
  lg: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "-4px",
      },
    },
    height: "56px",
    borderRadius: 8,
    margin: 7,
    padding: "1.125rem 2.25rem",
    "& .MuiButton-label": {
      fontSize: "20px",
    },

    lineHeight: "1.333333",
  },
  sm: {
    padding: "2px 4px",
    height: 30,
    fontSize: "12px",
    margin: 1,
  },
  md: {
    padding: "2px 5px",
    height: 30,
    minWidth: 100,
    fontSize: "14px",
    margin: 1,
  },
  round: {
    borderRadius: "30px",
  },
  block: {
    width: "100% !important",
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  justIcon: {
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "28px",
    fontSize: "20px",
    height: "56px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px",
      },
      "& svg": {
        width: "32px",
        height: "32px",
      },
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px",
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
  },
});

export default styles;
