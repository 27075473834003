import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_WRAP_BEGIN,
  FETCH_WRAP_SUCCESS,
  FETCH_WRAP_FAILURE,
} from "./constants";
import { chainList } from "../../configure";

import axios from "axios";
import _ from "lodash";

const chainTokenMapping = {
  1: "https://www.gemini.com/uniswap/manifest.json",
  56: "https://tokens.pancakeswap.finance/coingecko.json",
  66: "https://www.cherryswap.net/swapimages/json/t3/cherryswap.json",
  137: "https://unpkg.com/quickswap-default-token-list@1.2.39/build/quickswap-default.tokenlist.json",
  43114:
    "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/joe.tokenlist.json",
  10: "https://static.optimism.io/optimism.tokenlist.json",
  42161: "https://bridge.arbitrum.io/token-list-42161.json",
  108: "https://ttswap.space/api/tokens",
};
let nativeAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
export function fetchSwapDetail({ chainId }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_WRAP_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const requestData = await axios.get(chainTokenMapping[chainId], {
          withCredentials: false,
        });

        let output = _.reduce(
          chainId == 108
            ? requestData.data.data.tokenList
            : requestData.data.tokens,
          function (result, value, key) {
            if (
              value.chainId &&
              value.chainId.toString() !== chainId.toString()
            ) {
            } else {
              if (chainId == 66) {
                value.logoURI = `https://www.cherryswap.net${value.logoURI}`;
              }

              if (chainId == 108) {
                value.address = value.tokenAddress;
                value.logoURI = `https://ttswap.space/icons/${
                  value.tokenAddress ? value.tokenAddress.toLowerCase() : ""
                }.png`;
              }
              result.push(value);
            }
            return result;
          },
          []
        );
        const chain = _.find(chainList, ["value", chainId]);
        output = [
          {
            symbol: chain.nativeToken,
            address: nativeAddress,
            logoURI: require("assets/img/" + chain.nativeTokenIcon).default,
          },
          ...output,
        ];
        dispatch({
          type: FETCH_WRAP_SUCCESS,
          data: output,
        });
      } catch (error) {
        dispatch({
          type: FETCH_WRAP_FAILURE,
        });
        // console.log(error);
      }
    });

    return promise;
  };
}

export function useFetchSwapDetail() {
  const dispatch = useDispatch();

  const { tokenList, fetchSwapDetailPending } = useSelector(
    (state) => ({
      fetchSwapDetailPending: state.form.fetchSwapDetailPending,
      tokenList: state.form.tokenList,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchSwapDetail(data));
    },
    [dispatch]
  );

  return {
    tokenList,
    fetchSwapDetail: boundAction,
    fetchSwapDetailPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_WRAP_BEGIN:
      return {
        ...state,
        tokenList: [],
        fetchSwapDetailPending: true,
      };

    case FETCH_WRAP_SUCCESS:
      return {
        ...state,
        tokenList: action.data,
        fetchSwapDetailPending: false,
      };

    case FETCH_WRAP_FAILURE:
      return {
        ...state,
        tokenList: [],
        fetchSwapDetailPending: false,
      };

    default:
      return state;
  }
}
