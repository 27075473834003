import React from "react";
import _ from "lodash";
import { IconButton } from "@material-ui/core";
import ImageUploading from "react-images-uploading";
import ImageIcon from "@material-ui/icons/CropOriginal";
const ImageUpload = ({ value, onChange, customButton, notShowPreview }) => {
  const max = customButton ? 2 : 1;
  return (
    <ImageUploading
      value={value}
      onChange={(imageList, addUpdateIndex) => {
        let list = imageList;
        if (imageList.length > 0) {
          let file = imageList[imageList.length - 1];
          if (file.file.size > 10000000) {
            alert("Over Max File Size!");
            return;
          }
          list = [file];
        }

        onChange(list, addUpdateIndex);
      }}
      dataURLKey="data_url"
    >
      {({ imageList, onImageUpload, onImageRemove, dragProps, errors }) => {
        return (
          <>
            {customButton ? (
              <div
                onClick={onImageUpload}
                {...dragProps}
                style={{ cursor: "pointer" }}
              >
                {customButton}
              </div>
            ) : (
              <div>
                {imageList.length < max && (
                  <>
                    <IconButton
                      onClick={onImageUpload}
                      {...dragProps}
                      style={{ padding: 0 }}
                    >
                      <img
                        src={require("assets/img/ic_uploadImage.svg").default}
                        className="smIcon"
                        style={{ marginRight: "0" }}
                      />
                    </IconButton>
                  </>
                )}
                {imageList &&
                  !notShowPreview &&
                  imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      {/* <img
                        src={image["data_url"]}
                        alt=""
                        style={{
                          verticalAlign: "middle",
                          maxWidth: 50,
                          objectFit: "cover",
                          margin: 10,
                        }}
                      /> */}

                      <IconButton
                        onClick={() => {
                          onImageRemove(index);
                        }}
                        style={{ padding: 0, marginLeft: 10 }}
                      >
                        <img
                          src={require("assets/img/ic_trash.svg").default}
                          className="smIcon"
                          style={{ marginRight: "0" }}
                        />
                      </IconButton>
                    </div>
                  ))}
              </div>
            )}
          </>
        );
      }}
    </ImageUploading>
  );
};

export default ImageUpload;
