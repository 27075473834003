import React, { useEffect, useState } from "react";
import _ from "lodash";
import CustomListItem from "components/CustomListItem/CustomListItem";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
const Verify = ({
  questions,
  index,
  setValue,
  answers,
  settings,
  checkAgain,
  renderErrorMessages,
}) => {
  const setQuestionData = (index, label, value) => {
    let newData = [...(answers || questions)];
    _.set(newData, `[${index}].${label}`, value);
    setValue(newData);
  };

  const q = questions[index];
  if (settings) {
    return (
      <div style={{ padding: "0 5px" }}>
        <CustomListItem
          content
          nohover={true}
          label="User needs right answer to go next question"
        />
        <CustomOutlinedInput
          onBlur={checkAgain}
          noLine
          sm
          style={{ paddingLeft: 16, width: 300, fontSize: 12 }}
          value={_.get(q, "validation", "")}
          placeholder="Expected answer"
          onChange={(e) => setQuestionData(index, "validation", e.target.value)}
        />
        {renderErrorMessages("verify")}
      </div>
    );
  }
  return <div />;
};

export default Verify;
