import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  UPDATE_FORM_ACCESS_BEGIN,
  UPDATE_FORM_ACCESS_SUCCESS,
  UPDATE_FORM_ACCESS_FAILURE,
  FETCH_FORM_ACCESS_BEGIN,
  FETCH_FORM_ACCESS_SUCCESS,
  FETCH_FORM_ACCESS_FAILURE,
} from "./constants";
import { apiUrl } from "../../configure";
import _ from "lodash";
import axios from "axios";
import { enqueueSnackbar } from "features/common/redux/actions";
import * as Sentry from "@sentry/react";
export function fetchInvitedList({ page, role }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_FORM_ACCESS_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(
          `${apiUrl}api/forms/getInvitedForms?page=${page}&role=${
            role == "all" ? "" : role
          }`
        );

        dispatch({
          type: FETCH_FORM_ACCESS_SUCCESS,
          data: result.data,
          invitedTotalCount: result.headers["x-total-count"] || 0,
        });
        resolve();
      } catch (err) {
        dispatch({
          type: FETCH_FORM_ACCESS_FAILURE,
        });
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.message",
              _.get(err, "response.data.error", "ERROR")
            ),
            options: {
              variant: "error",
            },
          })
        );
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function updateFormAccess({ address, role, formId, dataListName }) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_FORM_ACCESS_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let r = {
          address,
          role,
          formId,
        };
        if (role == "remove") {
          r["isRemove"] = "true";
        }
        const result = await axios.post(
          `${apiUrl}api/manage/updateFormAccess`,
          r
        );
        dispatch({
          type: UPDATE_FORM_ACCESS_SUCCESS,
          data: result.data,
          formId,
          address,
          role,
          dataListName,
        });
        resolve();
      } catch (err) {
        console.log(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.message",
              _.get(err, "response.data.error", "ERROR")
            ),
            options: {
              variant: "error",
            },
          })
        );
        dispatch({
          type: UPDATE_FORM_ACCESS_FAILURE,
        });
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useFetchInvite() {
  const dispatch = useDispatch();

  const {
    invitedList,
    invitedTotalCount,
    fetchInvitedListPending,
    updateAccessPending,
  } = useSelector(
    (state) => ({
      invitedTotalCount: state.form.invitedTotalCount,
      invitedList: state.form.invitedList,
      fetchInvitedListPending: state.form.fetchInvitedListPending,
      updateAccessPending: state.form.updateAccessPending,
      createList: state.form.createList,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchInvitedList(data));
    },
    [dispatch]
  );

  const updateAction = useCallback(
    (data) => {
      return dispatch(updateFormAccess(data));
    },
    [dispatch]
  );

  return {
    invitedList,
    invitedTotalCount,
    fetchInvitedList: boundAction,
    updateFormAccess: updateAction,
    fetchInvitedListPending,
    updateAccessPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_FORM_ACCESS_BEGIN:
      return {
        ...state,
        fetchInvitedListPending: true,
      };

    case FETCH_FORM_ACCESS_SUCCESS:
      return {
        ...state,
        invitedList: action.data,
        invitedTotalCount: action.invitedTotalCount,
        fetchInvitedListPending: false,
      };

    case FETCH_FORM_ACCESS_FAILURE:
      return {
        ...state,
        invitedList: [],
        fetchInvitedListPending: false,
      };
    case UPDATE_FORM_ACCESS_BEGIN:
      return {
        ...state,
        updateAccessPending: true,
      };

    case UPDATE_FORM_ACCESS_SUCCESS:
      let createList = state[action.dataListName];
      const updateIndex = _.findIndex(createList, { _id: action.formId });
      let newAccess = [...createList[updateIndex].formAccess];
      if (action.role == "remove") {
        newAccess = newAccess.filter((item) => item.address !== action.address);
      } else {
        const updateAccessIndex = _.findIndex(newAccess, {
          address: action.address,
        });
        if (updateAccessIndex >= 0) {
          newAccess[updateAccessIndex] = action.data;
        } else {
          newAccess.push(action.data);
        }
      }
      createList[updateIndex] = {
        ...createList[updateIndex],
        formAccess: newAccess,
      };

      return {
        ...state,
        createList,
        updateAccessPending: false,
      };
    case UPDATE_FORM_ACCESS_FAILURE:
      return {
        ...state,
        updateAccessPending: false,
      };

    default:
      return state;
  }
}
