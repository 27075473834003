import React, { useEffect, useState } from "react";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import _ from "lodash";
const QuestionSelect = ({
  questions,
  index,
  setQuestions,
  blockchain = "evm",
}) => {
  let questionTypes = [
    {
      label: (
        <div className="startRow">
          <img
            src={require("assets/img/singleSelect.svg").default}
            className="smIcon"
          />
          Multiple Choice
        </div>
      ),
      value: "singleSelect",
    },
    {
      label: (
        <div className="startRow">
          <img
            src={require("assets/img/singleText.svg").default}
            className="smIcon"
          />
          Short Answer
        </div>
      ),
      value: "singleText",
    },
    {
      label: (
        <div className="startRow">
          <img
            src={require("assets/img/multipleText.svg").default}
            className="smIcon"
          />
          Paragraph
        </div>
      ),
      value: "multipleText",
    },
    {
      label: (
        <div className="startRow">
          <img
            src={require("assets/img/range.svg").default}
            className="smIcon"
          />
          Linear Scale
        </div>
      ),
      value: "range",
    },
    {
      label: (
        <div className="startRow">
          <img
            src={require("assets/img/file.svg").default}
            className="smIcon"
          />
          File
        </div>
      ),
      value: "file",
    },
    {
      label: (
        <div className="startRow">
          <img
            src={require("assets/img/statement.svg").default}
            className="smIcon"
          />
          Statement
        </div>
      ),
      value: "statement",
    },
    {
      label: (
        <div className="startRow">
          <img
            src={require("assets/img/verify.svg").default}
            className="smIcon"
          />
          Verify
        </div>
      ),
      value: "verify",
    },
    {
      label: (
        <div className="startRow">
          <img
            src={require("assets/img/bind.svg").default}
            className="smIcon"
          />
          Binding
        </div>
      ),
      value: "bind",
    },
  ];

  if (blockchain == "evm") {
    questionTypes.push({
      label: (
        <div className="startRow">
          <img
            src={require("assets/img/payment.svg").default}
            className="smIcon"
          />
          Payment
        </div>
      ),
      value: "payment",
    });
  }

  const setQuestionData = (index, label, value) => {
    let newData = [...questions];
    _.set(newData, `[${index}].${label}`, value);
    setQuestions(newData);
  };
  const q = questions[index];
  return (
    <div style={{ padding: "0 5px" }}>
      <CustomDropdown
        data={questionTypes}
        value={q.type}
        fullWidth
        disabled={q._id}
        onSelect={(e) => {
          const newValue = e.target.value;
          setQuestionData(index, "type", newValue);
          setQuestionData(index, "hasOther", false);
          setQuestionData(index, "multiple", false);
          setQuestionData(index, "content", null);
          setQuestionData(index, "discordValidation", null);
          setQuestionData(index, "img", require("assets/img/placeholder.png"));
          setQuestionData(index, "imgUpload", [
            {
              data_url: require("assets/img/placeholder.png"),
            },
          ]);
          let newOption = [{}, {}];
          if (newValue == "range") {
            setQuestionData(index, "from", 1);
            setQuestionData(index, "to", 5);
          } else {
            setQuestionData(index, "from", null);
            setQuestionData(index, "to", null);
            setQuestionData(index, "toLabel", null);
          }
          if (newValue == "singleText") {
            setQuestionData(index, "validation", "text");
          } else if (newValue == "bind") {
            setQuestionData(index, "validation", "Twitter");
            setQuestionData(
              index,
              "img",
              require("assets/img/defaultTwitterBanner.png")
            );
            setQuestionData(index, "imgUpload", [
              {
                data_url: require("assets/img/defaultTwitterBanner.png")
                  .default,
              },
            ]);
          } else {
            setQuestionData(index, "validation", "");
          }
          if (newValue == "payment") {
            setQuestionData(index, "isRequired", true);
          } else {
            setQuestionData(index, "payment", null);
          }
          if (newValue == "statement") {
            setQuestionData(index, "isRequired", false);
          }
          if (
            newValue == "verify" ||
            newValue == "bind" ||
            newValue == "payment"
          ) {
            setQuestionData(index, "isRequired", true);
          }

          if (newValue == "singleSelect")
            setQuestionData(index, "options", newOption);
        }}
      ></CustomDropdown>
    </div>
  );
};

export default QuestionSelect;
