import React from "react";
import { StyledStatusLabel } from "./StatusLabel.styles";

const StatusLabel = ({ size, type, children, ...otherProps }) => {
  if (!children) return null;
  return (
    <StyledStatusLabel size={size} type={type} {...otherProps}>
      {children}
    </StyledStatusLabel>
  );
};

export default StatusLabel;
