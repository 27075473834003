import React from "react";
import _ from "lodash";
import { IconButton } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
const imgCss = {
  verticalAlign: "middle",
  width: 70,
  height: 70,

  objectFit: "cover",
};

const ImagePreview = ({ imgs, onImageRemove }) => {
  const theme = useTheme();
  return (
    <div
      style={{ padding: 10, background: theme.sendFieldBackground }}
      className="startRow"
    >
      {imgs &&
        imgs.map((image, index) => {
          const isUrl = typeof image == "string";
          const fileType = isUrl
            ? image.split(".").pop()
            : image.file.name.split(".").pop();
          return (
            <span
              key={index}
              style={{
                position: "relative",
                border: "1px solid #555555",
                background: "#FFFFFF",
                margin: 10,
                marginLeft: 0,
                borderRadius: 10,
              }}
            >
              {fileType == "pdf" ? (
                <span className="startRow" style={{ padding: 10 }}>
                  <img src={require("assets/img/pdf.svg").default} alt="" />
                  <div style={{ padding: 9 }}>
                    <div style={{ fontWeight: 700 }}>
                      {_.truncate(image.file.name, { length: 10 })}
                    </div>
                    PDF
                  </div>
                </span>
              ) : (
                <img
                  src={_.get(image, "data_url", image)}
                  alt=""
                  style={imgCss}
                />
              )}

              <IconButton
                onClick={() => {
                  onImageRemove(index);
                }}
                style={{
                  padding: 0,
                  position: "absolute",
                  right: "-10px",
                  top: "-10px",
                }}
              >
                <img
                  src={require("assets/img/remove.svg").default}
                  className="smIcon"
                  style={{ marginRight: "0" }}
                />
              </IconButton>
            </span>
          );
        })}
    </div>
  );
};

export default ImagePreview;
