import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  SURVEY_LIST_FETCH_BEGIN,
  SURVEY_LIST_FETCH_SUCCESS,
  SURVEY_LIST_FETCH_FAILURE,
} from "./constants";
import { apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import axios from "axios";
import * as Sentry from "@sentry/react";
export function fetchSurveyList({ page }) {
  return (dispatch, getState) => {
    dispatch({
      type: SURVEY_LIST_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(`${apiUrl}api/forms?page=${page}`);
        const resultCompleted = await axios.get(
          `${apiUrl}api/forms?type=completed`
        );
        dispatch({
          type: SURVEY_LIST_FETCH_SUCCESS,
          data: result.data,
          completedList: resultCompleted.data,
          totalCount: result.headers["x-total-count"],
        });
      } catch (err) {
        dispatch({
          type: SURVEY_LIST_FETCH_FAILURE,
        });
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useFetchSurveyList() {
  const dispatch = useDispatch();

  const {
    surveyList,
    completedList,
    totalCount,
    fetchSurveyListPending,
    fetchSurveyListDone,
  } = useSelector(
    (state) => ({
      totalCount: state.form.totalCount,
      surveyList: state.form.surveyList,
      completedList: state.form.completedList,
      fetchSurveyListPending: state.form.fetchSurveyListPending,
      fetchSurveyListDone: state.form.fetchSurveyListDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchSurveyList(data));
    },
    [dispatch]
  );

  return {
    surveyList,
    completedList,
    totalCount,
    fetchSurveyList: boundAction,
    fetchSurveyListPending,
    fetchSurveyListDone,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SURVEY_LIST_FETCH_BEGIN:
      return {
        ...state,
        fetchSurveyListPending: true,
      };

    case SURVEY_LIST_FETCH_SUCCESS:
      return {
        ...state,
        surveyList: action.data,
        completedList: action.completedList,
        totalCount: action.totalCount,
        fetchSurveyListPending: false,
        fetchSurveyListDone: true,
      };

    case SURVEY_LIST_FETCH_FAILURE:
      return {
        ...state,
        surveyList: [],
        completedList: [],
        fetchSurveyListPending: false,
      };

    default:
      return state;
  }
}
