import React, { memo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Box from "@material-ui/core/Box";
import styles from "./styles";
import _, { set } from "lodash";

const useStyles = makeStyles(styles);

const CustomTable = ({
  headers,
  contents,
  type = "table",
  centerText = {},
  style = {},
  selectedRow = [],
  sortDataFunction,
  noBorderLeft = {},
  rowSelected,
  noHead,
  collapse,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");

  let titleClass = classes.tableTitle;
  let valueClass = classes.tableValue;
  if (type == "outlined") {
    titleClass = classes.outlinedTitle;
    valueClass = classes.outlinedValue;
  }
  if (type == "column") {
    titleClass = classes.columnTitle;
    valueClass = classes.columnValue;
  }
  let selectedStyle = rowSelected
    ? {
        cursor: "pointer",
        background: "#FFFFFF",
      }
    : {};
  return (
    <TableContainer className={classes.card}>
      <Table
        style={{
          borderCollapse: collapse ? "collapse" : "separate",
          borderSpacing: "0 10px",
        }}
      >
        {!noHead && (
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return (
                  <TableCell
                    key={index}
                    align={centerText[index] == true ? "center" : "left"}
                    className={`${classes.title} ${titleClass}`}
                    style={{ ...style }}
                  >
                    {/* <TableSortLabel
                    active={orderBy === header}
                    className={`tableTitle`}

                    direction={orderBy === header ? order : 'dec'}
                    disabled={true}
                    // onClick={()=>{
                    //   if(sortDataFunction){
                    //     setOrderBy(header);
                    //     sortDataFunction(header);
                    //   }
                
                    //   }
                    // }
                  > */}
                    {header}

                    {/* </TableSortLabel> */}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {_.map(contents, (row, index) => (
            <TableRow
              hover
              role="checkbox"
              className={classes.tableRow}
              key={index}
              style={selectedStyle}
              onClick={() => {
                rowSelected && rowSelected(index);
              }}
            >
              {row &&
                row.map((r, rIndex) => (
                  <TableCell
                    key={rIndex}
                    align={centerText[rIndex] == true ? "center" : "left"}
                    className={`${classes.value} ${valueClass} ${
                      selectedRow.includes(index) ? classes.selected : ""
                    }`}
                    style={{
                      position: "relative",
                      paddingLeft:
                        rIndex != 0 && noBorderLeft[rIndex] !== true ? 10 : 5,
                      ...style,
                    }}
                  >
                    {rIndex != 0 && noBorderLeft[rIndex] !== true && (
                      <div
                        className="absoluteBorderLeft"
                        style={{ left: 2, height: "50%", top: "25%" }}
                      />
                    )}
                    {r}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(CustomTable);
