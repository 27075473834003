import React, { useEffect, useState, useRef } from "react";
import { Popover } from "@material-ui/core";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";

import {
  StyledPopoverContainer,
  StyledPopoverHeader,
  StyledRewardPopoverContent,
} from "./RewardPopover.styles";

const RewardPopover = ({
  onClose,
  onAnchorEl,
  onRenderRestrictionPage,
  onRenderRewardPage,
}) => {
  return (
    <Popover
      onClose={onClose}
      open={Boolean(onAnchorEl)}
      anchorEl={onAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: -220,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <StyledPopoverContainer>
        <StyledPopoverHeader>
          <div className="title">Restriction and Reward Setting</div>
          <IconButton className="close-icon" onClick={onClose}>
            <img src={require("assets/img/x-circle.svg").default} />
          </IconButton>
        </StyledPopoverHeader>
        <StyledRewardPopoverContent>
          {onRenderRestrictionPage(false)}
          {onRenderRewardPage(false)}
        </StyledRewardPopoverContent>
      </StyledPopoverContainer>
    </Popover>
  );
};

RewardPopover.propTypes = {
  onClose: PropTypes.func,
  onAnchorEl: PropTypes.node,
  setFormData: PropTypes.func,
  formData: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  checkAgain: PropTypes.func,
};

RewardPopover.defaultProps = {
  onClose: () => {},
  onAnchorEl: null,
  setFormData: () => {},
  formData: {},
  errors: {},
  checkAgain: () => {},
};

export default RewardPopover;
