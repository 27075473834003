import React, { useEffect, useState } from "react";
import _ from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles((theme) => ({
  menuRoot: {
    zIndex: 7000,
  },
  menuPaper: {
    color: "#383538",
    borderRadius: "3px",
  },
  select: { color: "white", background: "#F1F1F1" },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 13,

    fontWeight: 700,
    "&.Mui-selected": {
      background: "#F1F1F1",
    },
    "&:hover": { background: "#F1F1F1" },
  },
  selected: {
    background: "#F1F1F1",
  },
}))(MenuItem);

const BootstrapInput = withStyles((theme) => ({
  root: {
    minWidth: 100,
  },
  input: (props) => ({
    borderRadius: 5,
    width: "100%",
    textAlign: "left",
    position: "relative",
    background: "#F1F1F1",
    fontFamily: "Inter",
    color: "#383538",
    fontSize: 13,
    fontWeight: 900,
    padding: "10px 15px",
    margin: props.margin,
    "&:focus": {
      borderRadius: 5,
      background: "#F1F1F1",
    },
  }),
}))(InputBase);

const WhiteBootstrapInput = withStyles((theme) => ({
  root: {
    minWidth: 100,
  },
  input: (props) => ({
    borderRadius: 5,
    border: "1px solid #A5A5A5",
    width: "100%",
    textAlign: "left",
    position: "relative",
    background: "#FFFFFF",
    fontFamily: "Inter",
    color: "#383538",
    minHeight: 25,
    fontSize: 13,
    fontWeight: 700,
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    margin: props.margin,
    "&:focus": {
      borderRadius: 5,
      background: "#FFFFFF",
    },
  }),
}))(InputBase);

export const CustomDropdown = ({
  data,
  onSelect,
  value,
  style,
  type,
  white,
  fullWidth,
  disabled,
  placeholder,
  margin = "5px 0",
  ...props
}) => {
  const classes = useStyles();
  const inputProps = { margin };

  const inputType = white ? (
    <WhiteBootstrapInput {...inputProps} />
  ) : (
    <BootstrapInput {...inputProps} />
  );

  return (
    <Select
      disabled={disabled}
      value={value}
      fullWidth={fullWidth}
      variant="outlined"
      MenuProps={{
        disableScrollLock: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        MenuListProps: { disablePadding: true },
        classes: { paper: classes.menuPaper, root: classes.menuRoot },
      }}
      style={{ ...style }}
      input={inputType}
      // IconComponent={(props) => (
      //   <ExpandMoreIcon
      //     {...props}
      //   />
      // )}
      classes={{ select: classes.select }}
      onChange={onSelect}
      displayEmpty={true}
      renderValue={(selected) => {
        if (!selected || selected.length === 0) {
          return <em>{placeholder ? placeholder : ""}</em>;
        }
        let match = _.find(data, ["value", selected]);
        return _.get(match, "label", "");
      }}
      {...props}
    >
      {data.map((d) => (
        <StyledMenuItem key={d.value} value={d.value} disabled={d.disabled}>
          {d.label}
        </StyledMenuItem>
      ))}
    </Select>
  );
};

export default CustomDropdown;
