import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  CREATOR_QUESTION_RESPONSE_FETCH_BEGIN,
  CREATOR_QUESTION_RESPONSE_FETCH_SUCCESS,
  CREATOR_QUESTION_RESPONSE_FETCH_FAILURE,
} from "./constants";
import { apiUrl } from "../../configure";
import axios from "axios";
import _ from "lodash";
import * as Sentry from "@sentry/react";
axios.defaults.withCredentials = true;

export function fetchCreatorQuestionResponseData({
  formId,
  questionId,
  page,
  limit,
  filterZero = 0,
  dump = 0,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATOR_QUESTION_RESPONSE_FETCH_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(
          apiUrl +
            `api/forms/${formId}/responses/${questionId}?page=${
              page + 1
            }&limit=${limit}&dump=${dump}&filterZero=${filterZero}`
        );
        let output = result.data;

        dispatch({
          type: CREATOR_QUESTION_RESPONSE_FETCH_SUCCESS,
          data: output,
          formId,
          questionId,
        });
      } catch (err) {
        dispatch({
          type: CREATOR_QUESTION_RESPONSE_FETCH_FAILURE,
        });
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useFetchCreatorQuestionResponseData() {
  const dispatch = useDispatch();

  const {
    creatorQuestionResponseData,
    fetchCreatorQuestionResponseDataPending,
  } = useSelector(
    (state) => ({
      creatorQuestionResponseData: state.form.creatorQuestionResponseData,
      fetchCreatorQuestionResponseDataPending:
        state.form.fetchCreatorQuestionResponseDataPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchCreatorQuestionResponseData(data));
    },
    [dispatch]
  );

  return {
    creatorQuestionResponseData,
    fetchCreatorQuestionResponseData: boundAction,
    fetchCreatorQuestionResponseDataPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATOR_QUESTION_RESPONSE_FETCH_BEGIN:
      return {
        ...state,
        fetchCreatorQuestionResponseDataPending: true,
      };

    case CREATOR_QUESTION_RESPONSE_FETCH_SUCCESS:
      return {
        ...state,
        creatorQuestionResponseData: {
          ...state.creatorQuestionResponseData,
          [action.questionId]: action.data,
        },
        fetchCreatorQuestionResponseDataPending: false,
      };

    case CREATOR_QUESTION_RESPONSE_FETCH_FAILURE:
      return {
        ...state,
        fetchCreatorQuestionResponseDataPending: false,
      };

    default:
      return state;
  }
}
