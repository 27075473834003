import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledBackButton = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  color: theme.customColors.grey[700],
  cursor: "pointer",
  display: "inline-flex",
  alignItems: "center",
}));
