import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledPrivacyPolicyPageContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingTop: "194px",
  paddingBottom: "66px",
  maxWidth: "880px",
  margin: "0 auto",
  paddingLeft: "30px",
  paddingRight: "30px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "85px",
  },
}));

export const StyledPrivacyPolicyPageTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "36px",
  fontWeight: 700,
  color: theme.customColors.grey[900],
  position: "relative",
  textAlign: "center",
  marginBottom: "90px",
  "&:before": {
    content: '""',
    position: "absolute",
    height: "1px",
    background: theme.customColors.grey[400],
    maxWidth: "260px",
    width: "30%",
    left: 0,
    top: "50%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "75px",
      width: "15%",
    },
  },

  "&:after": {
    content: '""',
    position: "absolute",
    height: "1px",
    background: theme.customColors.grey[400],
    maxWidth: "260px",
    width: "30%",
    right: 0,
    top: "50%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "75px",
      width: "15%",
    },
  },
}));

export const StyledPrivacyPolicyContentContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledPrimaryList = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledPrimaryListTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[800],
  fontSize: "28px",
  fontWeight: 700,
  marginBottom: "24px",
}));

export const StyledPrimaryTitleOrder = styled("span", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledSecondaryListContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledSecondaryList = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledSingleSecondaryContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  marginBottom: "24px",
  fontSize: "18px",
  fontWeight: 500,
}));

export const StyledSingleSecondaryOrder = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[500],
  fontSize: "18px",
  marginRight: "12px",
}));

export const StyledSingleSecondaryContent = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[700],
}));
