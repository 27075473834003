import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";

export const StyledCloseBlock = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    background: theme.customColors.grey[900],
    borderRadius: "30px",
    width: "79px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      cursor: "pointer",
    },

    "& .close-text": {
      fontWeight: 500,
      fontSize: 14,
      marginRight: "8px",
    },
  };
});

export const StyledCloseBtn = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontWeight: theme.fontWeight.BOLD,
    color: theme.customColors.white,
  };
});
