import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  CREATE_RESPONSE_BEGIN,
  CREATE_RESPONSE_FAILURE,
  CREATE_RESPONSE_SUCCESS,
  CREATE_RESPONSE_PROGRESS,
} from "./constants";
import { enqueueSnackbar } from "features/common/redux/actions";
import { apiUrl } from "../../configure";
import axios from "axios";
import _ from "lodash";
import * as Sentry from "@sentry/react";

axios.defaults.withCredentials = true;
export function createResponse({ formData, answers, questions }) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_RESPONSE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        var postFormData = new FormData();
        postFormData.append("formId", formData._id);
        for (let answer of answers) {
          if (answer?.file) {
            for (let file of answer.file) {
              postFormData.append("response", file?.file);
            }
          }
        }
        const options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch({
              type: CREATE_RESPONSE_PROGRESS,
              data: percentCompleted,
            });
          },
        };
        const uploadResult = await axios.post(
          apiUrl + "api/gcp/uploadResponse",
          postFormData,
          options
        );

        const answerFiles = _.get(uploadResult.data, "response");
        let fileIndex = 0;
        const formatAnswers = _.cloneDeep(answers);
        for (let i = 0; i < answers.length; i++) {
          if (!answers[i] || !answers[i].file) continue;
          for (let file of answers[i].file) {
            let answerFile = file.file;
            if (answerFile) {
              if (!formatAnswers[i].value) formatAnswers[i].value = [];
              formatAnswers[i].value.push(_.nth(answerFiles, fileIndex));
              delete formatAnswers[i].file;
              fileIndex++;
            }
          }
        }
        let responses = [];
        for (let i = 0; i < questions.length; i++) {
          const qAnswer = formatAnswers[i];
          if (questions[i].multiple) {
            qAnswer.value = _.map(qAnswer.value, "value");
          }

          responses.push({
            response: qAnswer,
            question: questions[i],
          });
        }
        const body = {
          formId: formData._id,
          responses,
        };
        const result = await axios.post(apiUrl + "api/forms/response", body);
        dispatch(
          enqueueSnackbar({
            message: "Reply Success : " + result.data._id,
            options: {
              variant: "success",
            },
          })
        );
        dispatch({
          type: CREATE_RESPONSE_SUCCESS,
          data: result.data.id,
        });
        resolve(result.data.id);
      } catch (err) {
        Sentry.captureException(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(err, "response.data.error", err.toString()),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: CREATE_RESPONSE_FAILURE,
        });
      }
    });

    return promise;
  };
}

export function useCreateResponse() {
  const dispatch = useDispatch();

  const { createdResponse, createResponsePending } = useSelector(
    (state) => ({
      createdResponse: state.form.createdResponse,
      createResponsePending: state.form.createResponsePending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(createResponse(data));
    },
    [dispatch]
  );

  return {
    createdResponse,
    createResponse: boundAction,
    createResponsePending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATE_RESPONSE_BEGIN:
      return {
        ...state,
        createResponsePending: true,
      };

    case CREATE_RESPONSE_SUCCESS:
      return {
        ...state,
        createdResponse: action.data,
        uploadFileProgress: 0,
        createResponsePending: false,
      };

    case CREATE_RESPONSE_PROGRESS:
      return {
        ...state,
        uploadFileProgress: action.data,
      };

    case CREATE_RESPONSE_FAILURE:
      return {
        ...state,
        createResponsePending: false,
        uploadFileProgress: 0,
      };

    default:
      return state;
  }
}
