const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  connectDesc: {
    fontSize: 14,
    fontWeight: 600,
    maxWidth: 200,
    margin: "5px auto",
  },
  connectText: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 10,
  },
  desc: {
    transform: "scale(0.8)",
    color: "#777",
  },
  header: {
    margin: 10,
    fontWeight: 900,
    fontSize: 17,
    textAlign: "center",
  },
  subHeader: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: "center",
  },
  title: { fontSize: 20, fontWeight: 700 },
  subtitle: { fontSize: 14, fontWeight: 400 },
  modalPaper: {
    width: 500,
    minHeight: 285,
    padding: "48px 38px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "auto",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    outline: "none",
    lineHeight: 1.4,
  },
  active: {
    "& $line": {
      borderColor: "#2B9BFF",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#2B9BFF",
    },
  },

  disabled: {
    cursor: "not-allowed",
    border: "2px solid #383538",
  },
});

export default styles;
