import styled from "styled-components";
import { Grid } from "@material-ui/core";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

export const StyledRewardEditorContainer = styled.div`
  min-width: 500px;
  max-width: 500px;

  & .card {
    padding: 0;
  }

  & .betweenRow {
    padding: 20px 15px;
  }

  & .setting-content {
    border-top: 1px solid #a5a5a5;
  }
`;

export const StyledRewardContentContainer = styled(Grid)`
  padding: 12px;
  border-top: 1px solid #a5a5a5;
`;

export const StyledRewardGridItem = styled(Grid)`
  padding: 0 4px;

  &.cardDesc {
    font-size: 10px;
    color: #555555;
  }

  & .MuiSelect-root,
  & .MuiFormControl-root {
    margin-bottom: 12px;
  }
`;

export const StyledCustomDropdown = styled(CustomDropdown)`
  & .MuiSelect-icon {
    right: -5px;
    top: calc(50% - 15px);
  }
`;
