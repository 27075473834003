import { useCallback } from "react";
import { distributeNFTABI } from "../../configure";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_DISTRIBUTE_BEGIN,
  FETCH_DISTRIBUTE_SUCCESS,
  FETCH_DISTRIBUTE_FAILURE,
} from "./constants";
import { enqueueSnackbar } from "../../common/redux/actions";
import { ethers } from "ethers";
import _ from "lodash";
import { fetchFormData } from "./fetchFormData";
import { fetchCreateList } from "./fetchCreateList";
import { updateReward } from "./updateReward";
import { writeContract, prepareWriteContract } from "@wagmi/core";
export function fetchDistributeNFT({
  addresses,
  ids,
  tokenAddress,
  contractAddress,
  formId,
  address,
}) {
  return async (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_DISTRIBUTE_BEGIN,
    });
    try {
      const config = await prepareWriteContract({
        address: contractAddress,
        abi: distributeNFTABI,
        functionName: "distributeRewardNFT",
        args: [tokenAddress, addresses, ids],
      });
      const { hash } = await writeContract(config);

      dispatch(
        enqueueSnackbar({
          message: hash,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
          hash,
        })
      );

      dispatch(
        fetchFormData({
          formId,
        })
      );
      dispatch(
        fetchCreateList({
          address,
        })
      );

      dispatch(
        updateReward({
          formId,
          reward: {
            distributeTx: hash,
            distributedData: [{ txHash: hash, addresses }],
          },
          address,
        })
      );

      dispatch({
        type: FETCH_DISTRIBUTE_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: FETCH_DISTRIBUTE_FAILURE });
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "Error"),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
    }
  };
}

export function useFetchDistributeNFT() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { fetchDistributePending } = useSelector((state) => ({
    fetchDistributePending: state.form.fetchDistributePending,
  }));

  const boundAction = useCallback(
    (data) => dispatch(fetchDistributeNFT(data)),
    [dispatch]
  );

  return {
    fetchDistributeNFT: boundAction,
    fetchDistributePending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_DISTRIBUTE_BEGIN:
      return {
        ...state,
        fetchDistributePending: true,
      };

    case FETCH_DISTRIBUTE_SUCCESS:
    case FETCH_DISTRIBUTE_FAILURE:
      return {
        ...state,
        fetchDistributePending: false,
      };

    default:
      return state;
  }
}
