import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  UPDATE_REWARD_BEGIN,
  UPDATE_REWARD_SUCCESS,
  UPDATE_REWARD_FAILURE,
} from "./constants";
import { apiUrl } from "../../configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import axios from "axios";
import _ from "lodash";
import { fetchFormData } from "./fetchFormData";
import { fetchCreateList } from "./fetchCreateList";
import * as Sentry from "@sentry/react";
export function updateReward({ formId, reward, address }) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_REWARD_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let postData = { ...reward };
        if (!postData.hasDistributionCriteria) {
          postData.distributionCriteria = [];
          postData.distributionRestritions = {};
        }

        const result = await axios.post(apiUrl + "api/forms/updateReward", {
          formId,
          ...postData,
        });
        dispatch(
          enqueueSnackbar({
            message: "Rewards Successfully Updated: " + formId,
            options: {
              variant: "success",
            },
          })
        );
        dispatch(
          fetchFormData({
            formId,
          })
        );

        dispatch({
          type: UPDATE_REWARD_SUCCESS,
          data: result.data,
        });
        resolve();
      } catch (err) {
        dispatch({
          type: UPDATE_REWARD_FAILURE,
        });
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.message",
              _.get(err, "response.data.error", "ERROR")
            ),
            options: {
              variant: "error",
            },
          })
        );
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useUpdateReward() {
  const dispatch = useDispatch();

  const { updateRewardPending, updatedReward } = useSelector(
    (state) => ({
      updateRewardPending: state.form.updateRewardPending,
      updatedReward: state.form.updatedReward,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(updateReward(data));
    },
    [dispatch]
  );

  return {
    updateReward: boundAction,
    updateRewardPending,
    updatedReward,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_REWARD_BEGIN:
      return {
        ...state,
        updateRewardPending: true,
      };

    case UPDATE_REWARD_SUCCESS:
      return {
        ...state,
        updatedReward: action.data,
        updateRewardPending: false,
      };

    case UPDATE_REWARD_FAILURE:
      return {
        ...state,
        updateRewardPending: false,
      };

    default:
      return state;
  }
}
