import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { chainList } from "features/configure";
const useStyles = makeStyles((theme) => ({
  headerBg: {
    background: "rgba(255, 255, 255, 0.25)",
    backdropFilter: "blur(6.23961px)",
    padding: 10,
  },
}));
const getChainLabel = (chainId) => {
  return _.get(_.find(chainList, { value: chainId }), "label", "");
};
const RewardField = ({ reward }) => {
  const classes = useStyles();
  return (
    <div style={{ textAlign: "left" }}>
      {reward && reward.hasReward && reward.rewardAmount && (
        <div style={{ margin: "10px 0" }} className={classes.headerBg}>
          <div>
            <div style={{ fontSize: 17, fontWeight: 700 }} className="startRow">
              <img
                src={require("assets/img/gift.svg").default}
                className="smIcon"
              />
              Reward:
            </div>
            <div
              style={{
                fontSize: 13,
                fontWeight: 700,
                lineHeight: 1.5,
              }}
            >
              Complete this survey for a chance to win
              <span className="highlight">
                {` ${_.get(reward, "rewardAmount", "")} ${
                  reward.symbol || reward.address || ""
                }`}
              </span>
              (Chain:
              <span className="highlight">
                {getChainLabel(_.get(reward, "chainId"))}
              </span>
              )
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RewardField;
