import { byDecimals } from "./bignumber";
import Papa from "papaparse";
let trimReg = /(^\s*)|(\s*$)/g;

export function isEmpty(key) {
  if (key === undefined || key === "" || key === null) {
    return true;
  }
  if (typeof key === "string") {
    key = key.replace(trimReg, "");
    return (
      key === "" ||
      key === null ||
      key === "null" ||
      key === undefined ||
      key === "undefined"
    );
  } else if (typeof key === "undefined") {
    return true;
  } else if (typeof key == "object") {
    for (let i in key) {
      return false;
    }
    return true;
  } else if (typeof key == "boolean") {
    return false;
  }
}

let inputReg = /[a-z]/i;
export function inputLimitPass(value, tokenDecimals) {
  let valueArr = value.split(".");
  return !(
    inputReg.test(value) ||
    (valueArr.length === 2 && valueArr[1].length > tokenDecimals)
  );
}

export function inputFinalVal(value, total, tokenDecimals) {
  let inputVal = Number(value.replaceAll(",", ""));
  return inputVal > total
    ? byDecimals(total, 0).toFormat(tokenDecimals)
    : value;
}

export const converAprToApy = (apr) => {
  return Math.pow(1 + apr / 365, 365) - 1;
};

export const converAprStringToApyString = (aprString) => {
  const apr = parseFloat(aprString.replace("%", "")) / 100;
  return parseFloat(converAprToApy(apr) * 100).toFixed(2) + "%";
};

export const convertApyFloatToString = (apyFloat) => {
  return (apyFloat * 100).toFixed(2) + "%";
};

export const convertAprFloatToString = (aprFloat) => {
  return parseFloat(converAprToApy(aprFloat) * 100).toFixed(2) + "%";
};

export const getShortAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 6)}......${address.slice(-4)}`;
};

export const validateAptosAddress = (address) => {
  if (typeof address !== "string") {
    return false;
  }

  // Check for the correct length (2 characters for '0x' + 64 characters for the 32-byte hexadecimal string)
  if (address.length !== 66) {
    return false;
  }

  // Check if the address starts with '0x'
  if (!address.startsWith("0x")) {
    return false;
  }

  // Extract the part after '0x' and check if it contains only hexadecimal characters
  const hexPart = address.substring(2);
  const hexPattern = /^[0-9a-fA-F]+$/;

  return hexPattern.test(hexPart);
};

export const exportCSV = (data, name = "MetaForm_Export_Users") => {
  const csv = Papa.unparse(data);
  const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const csvURL = window.URL.createObjectURL(csvData);
  const anchorElement = document.createElement("a");
  document.body.appendChild(anchorElement);
  anchorElement.style.display = "none";
  anchorElement.href = csvURL;
  anchorElement.download = name;
  anchorElement.click();
  window.URL.revokeObjectURL(csvURL);
};
