import React, { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";

const AntTabs = withStyles({
  root: { minHeight: 30 },
  indicator: {
    background: "transparent",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 70,
    fontWeight: 500,
    marginRight: 5,
    padding: 3,
    minHeight: 20,

    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      backgroundColor: "white",
      "& .MuiTab-wrapper": {
        fontWeight: 700,
        color: "#383538",
      },
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const MetaTabs = withStyles({
  root: { minHeight: 30 },
  indicator: {
    background: "transparent",
  },
})(Tabs);
const MetaTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 70,
    fontWeight: 500,
    marginRight: 5,
    padding: 3,
    minHeight: 20,
    borderRadius: 5,
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      borderBottom: "8px solid #0096FF",
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ overflow: "hidden" }}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#ABABAB",

    marginLeft: "auto",
    marginRight: "auto",
  },
  tabs: {
    color: "#383538",
    overflow: "hidden",
    borderRadius: 3,
    margin: 3,
    backgroundColor: "#ABABAB",
  },
  tab: {
    fontSize: 12,
    borderRadius: 3,
    margin: 3,
    textTransform: "capitalize",
    border: "0px",
    "& .MuiTab-wrapper": {
      fontWeight: 700,
      color: "#ffffff",
    },
  },
  metaTabs: {
    color: "#383538",
    overflow: "hidden",

    borderRadius: 3,
    margin: 3,
  },
  metaTab: {
    fontSize: 20,
    fontWeight: 700,
    borderRadius: 3,
    margin: 3,

    textTransform: "capitalize",
    border: "0px",
    "& .MuiTab-wrapper": {
      fontWeight: 700,
      color: "#383538",
    },
  },
}));

export default function CustomTabs({
  tabs,
  initIndex = 0,
  tabIndex,
  handleChange,
  metaform,
  sm,
  ...otherProps
}) {
  const [value, setValue] = useState(parseInt(initIndex));
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  return (
    <div>
      {metaform ? (
        <span className={classes.metaTabs}>
          <MetaTabs
            value={typeof tabIndex === "number" ? tabIndex : value}
            onChange={handleChange}
            centered
            indicatorColor="primary"
            {...otherProps}
          >
            {tabs.map((tab, index) => {
              let color =
                tabIndex == index
                  ? "linear-gradient(0deg, #9FCCFF 0%, rgba(255, 255, 255, 0) 112.5%)"
                  : "";
              return (
                <MetaTab
                  key={index}
                  style={{
                    background: color,
                    fontSize: computer ? (sm ? 14 : 20) : 12,
                    minWidth: computer ? 90 : 0,
                  }}
                  className={`${classes.metaTab}`}
                  label={tab.label}
                  disabled={tab.disabled}
                  {...a11yProps(index)}
                />
              );
            })}
          </MetaTabs>{" "}
        </span>
      ) : (
        <div position="static" className={classes.tabs}>
          <AntTabs
            // TabIndicatorProps={{ style: { background: "transparent" } }}
            value={typeof tabIndex === "number" ? tabIndex : value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="fullWidth"
            {...otherProps}
          >
            {tabs.map((tab, index) => {
              // let color =
              //   tabIndex == index ? theme.palette.background.primary : "";
              return (
                <AntTab
                  key={index}
                  // style={{ backgroundColor: color }}
                  className={`${classes.tab}`}
                  label={tab.label}
                  disabled={tab.disabled}
                  {...a11yProps(index)}
                />
              );
            })}
          </AntTabs>{" "}
        </div>
      )}

      {tabs.map((tab, index) => {
        return (
          <TabPanel
            key={index}
            value={typeof tabIndex === "number" ? tabIndex : value}
            index={index}
          >
            {tab.content}
          </TabPanel>
        );
      })}
    </div>
  );
}
