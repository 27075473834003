import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  AUTH_CHECK_BEGIN,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_FAILURE,
} from "./constants";
import { apiUrl } from "features/configure";
import _ from "lodash";
import axios from "axios";
import { useDisconnect } from "wagmi";
axios.defaults.withCredentials = true;

export function authCheck() {
  return async (dispatch) => {
    dispatch({
      type: AUTH_CHECK_BEGIN,
    });

    try {
      const res = await axios.get(`${apiUrl}api/session/info`);
      dispatch({
        type: AUTH_CHECK_SUCCESS,
        userData: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_CHECK_FAILURE,
      });
      // console.log(err);
    }
  };
}

const signoutAction = async () => {
  try {
    await axios.get(`${apiUrl}api/session/sign-out`);
  } catch (err) {
    // console.log(err);
  }
};

export function signout(disconnect) {
  return async (dispatch) => {
    try {
      await signoutAction();
      disconnect();
      dispatch({ type: AUTH_CHECK_FAILURE });
    } catch (err) {
      // console.log(err);
    }
  };
}

export function useConnectWallet() {
  const dispatch = useDispatch();
  const { userData, auth, checkAuth, authCheckPending } = useSelector(
    (state) => ({
      userData: state.home.userData,
      auth: state.home.auth,
      checkAuth: state.home.checkAuth,
      authCheckPending: state.home.authCheckPending,
    }),
    shallowEqual
  );
  const authAction = useCallback(() => dispatch(authCheck()), [dispatch]);
  const { disconnect } = useDisconnect();
  const signoutAction = useCallback(
    () => dispatch(signout(disconnect)),
    [dispatch]
  );
  return {
    userData,
    auth,
    checkAuth,
    authCheckPending,
    authCheck: authAction,
    signout: signoutAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_CHECK_BEGIN:
      return {
        ...state,
        authCheckPending: true,
      };
    case AUTH_CHECK_SUCCESS:
      const newData = {
        ...state,
        checkAuth: true,
        authCheckPending: false,
      };
      if (
        _.get(action, "userData.address") !==
        _.get(state, "userData.address", "NoAddress")
      ) {
        newData.userData = action.userData;
        newData.auth = action.userData.authorized;
      }

      return newData;

    case AUTH_CHECK_FAILURE:
      return {
        ...state,
        userData: undefined,
        auth: false,
        checkAuth: true,
        authCheckPending: false,
      };

    default:
      return state;
  }
}
