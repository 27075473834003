import { createBrowserRouter, Navigate } from "react-router-dom";
import {
  CreateForm,
  FillForm,
  CreateFormList,
  CreatorResponseList,
  OverviewResponseList,
  CreatorMenuList,
  SurveyFormList,
  FormInfo,
  Logout,
  Share,
  Reward,
  Auth,
} from "features/form";
import { Home, PrivacyPolicy } from "features/home/pages";

import { App, Landing } from "features/home";

const route = createBrowserRouter([
  {
    path: "user/:formId",
    element: <FillForm fullPage={"true"} />,
  },
  {
    path: "/",
    element: <App />,
    errorElement: <Landing />,
    children: [
      {
        path: "/",
        element: <Landing />,
        children: [
          { index: true, element: <Home /> },
          { path: "privacy-policy", element: <PrivacyPolicy /> },
        ],
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "auth/:type",
        element: <Auth />,
      },

      { path: "user", element: <SurveyFormList /> },
      {
        path: "creator/create",
        element: <CreateForm />,
      },

      {
        path: "creator/create/:formId",
        element: <CreateForm />,
      },
      {
        path: "creator/:formId",
        element: <FormInfo />,
        children: [
          {
            path: "preview",
            element: <FillForm fullPage={false} />,
          },
          {
            path: "share",
            element: <Share hideBackToList="true" />,
          },
          {
            path: "responses",
            element: <CreatorResponseList />,
          },
          {
            path: "dataDashboard",
            element: <OverviewResponseList />,
          },
          {
            path: "rewardSettings",
            element: <Reward />,
          },
          {
            path: "distributionSettings",
            element: <Reward tabId={1} />,
          },
          {
            path: "distributionRecord",
            element: <Reward tabId={2} />,
          },
        ],
      },
      {
        path: "creator",
        element: <CreatorMenuList />,
        children: [
          { index: true, element: <CreateFormList /> },

          { path: "invited", element: <CreateFormList invited={true} /> },
        ],
      },
    ],
  },
]);

export default route;
