import React, { useLayoutEffect, useEffect, useCallback } from "react";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { Notifier } from "features/common";
import appStyle from "./jss/appStyle.js";
import { Outlet } from "react-router-dom";
import ColorModeContext from "@metacrm/metacrm-material-ui/dist/contexts/ColorMode.context";
import useColorMode from "@metacrm/metacrm-material-ui/dist/hooks/useColorMode.hooks";
import LandingHeader from "./components/LandingHeader.js";
import SocialIcons from "./components/socialIcons/SocialIcons.js";
import ConnectWallet from "components/ConnectWallet/ConnectWallet.js";
import FooterEmail from "./components/Footer/FooterEmail";

const useStyles = makeStyles(appStyle);

export default function App() {
  const { colorModeHooksValue, theme } = useColorMode();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));

  const classes = useStyles();

  function Wrapper(props) {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return props.children;
  }

  return (
    <Wrapper>
      <main>
        <LandingHeader
          maxWidth={1200}
          extra={
            computer ? (
              <div className={"endRow"}>
                <SocialIcons />
              </div>
            ) : (
              <div style={{ marginTop: 20 }}>
                <ConnectWallet />
                <div style={{ opacity: 0.5, marginTop: 66 }}>Contact us</div>
                <div
                  style={{
                    opacity: 0.5,
                    border: "0.2px solid #000000",
                    margin: "8px auto",
                    width: 16,
                    marginBottom: 16,
                  }}
                />
                <FooterEmail computer={computer} />
                <div style={{ opacity: 0.5, marginTop: 20 }}>Follow us</div>
                <div
                  style={{
                    opacity: 0.5,
                    border: "0.2px solid #000000",
                    margin: "8px auto",
                    width: 16,
                    marginBottom: 16,
                  }}
                />
                <SocialIcons />
              </div>
            )
          }
        />
        <Outlet />
        <Notifier />
      </main>
    </Wrapper>
  );
}
