import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  CREAT_LIST_FETCH_BEGIN,
  CREAT_LIST_FETCH_SUCCESS,
  CREAT_LIST_FETCH_FAILURE,
} from "./constants";
import { apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import axios from "axios";
import * as Sentry from "@sentry/react";
export function fetchCreateList({ page }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CREAT_LIST_FETCH_BEGIN,
      });
      const result = await axios.get(apiUrl + "api/forms/creator?page=" + page);
      dispatch({
        type: CREAT_LIST_FETCH_SUCCESS,
        data: result.data,
        creatorTotalCount: result.headers["x-total-count"] || 0,
      });
    } catch (err) {
      dispatch({
        type: CREAT_LIST_FETCH_FAILURE,
      });
      Sentry.captureException(err);
    }
  };
}

export function useFetchCreateList() {
  const dispatch = useDispatch();

  const {
    createList,
    fetchCreateListPending,
    creatorTotalCount,
    fetchCreateListDone,
  } = useSelector(
    (state) => ({
      createList: state.form.createList,
      fetchCreateListPending: state.form.fetchCreateListPending,
      fetchCreateListDone: state.form.fetchCreateListDone,
      creatorTotalCount: state.form.creatorTotalCount,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchCreateList(data));
    },
    [dispatch]
  );

  return {
    createList,
    fetchCreateList: boundAction,
    fetchCreateListPending,
    fetchCreateListDone,
    creatorTotalCount,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREAT_LIST_FETCH_BEGIN:
      return {
        ...state,
        fetchCreateListPending: true,
      };

    case CREAT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        createList: action.data,
        fetchCreateListDone: true,
        creatorTotalCount: action.creatorTotalCount,
        fetchCreateListPending: false,
      };

    case CREAT_LIST_FETCH_FAILURE:
      return {
        ...state,
        createList: [],
        fetchCreateListPending: false,
      };

    default:
      return state;
  }
}
