import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const StyledLoadingButton = styled(Button)`
  border: 1px solid #0096ff;
  box-shadow: 2.21296px 2.21296px 0.737654px rgba(0, 0, 0, 0.2);
  padding: 6px 8px;
  color: #0096ff;
  font-size: 13px;
  text-transform: capitalize;

  &.Mui-disabled {
    color: #0096ff;
    background: #fff;
    & span {
      color: #0096ff;
    }
  }

  & img {
    width: 25px;
    height: 25px;
    margin-left: 8px;
  }
`;
