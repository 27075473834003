import React, { useRef, useEffect } from "react";
import Header from "@editorjs/header";
import EditorJS from "@editorjs/editorjs";
import _ from "lodash";
import * as Sentry from "@sentry/react";
const colorCollections = [
  "#FF1300",
  "#EC7878",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#0070FF",
  "#03A9F4",
  "#00BCD4",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFF",
];
const defaultColor = "#FF1300";
export default function TextEditor({
  value,
  onChange,
  readOnly,
  placeholder,
  id,
  noLine,
}) {
  const holderId = id || "editorjs";
  const ejInstance = useRef();

  useEffect(async () => {
    try {
      if (ejInstance?.current) {
        ejInstance?.current?.destroy();
        ejInstance.current = null;
      }
      await initEditor();
    } catch (err) {
      // console.log(err);
    }
  }, [id]);

  const onChangeRef = useRef();

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const initEditor = async () => {
    try {
      const comp = document.getElementById(holderId);
      if (!comp) return;
      // console.log(comp);
      const editor = new EditorJS({
        holder: holderId,
        logLevel: "ERROR",
        readOnly,
        placeholder: readOnly ? "" : placeholder,
        data: {
          blocks: value
            ? isJsonArray(value)
              ? JSON.parse(value)
              : [{ type: "paragraph", data: { text: value } }]
            : [],
        },
        onReady: () => {
          ejInstance.current = editor;
        },
        onChange: handleEditorTextChange,
        // autofocus: true,
        tools: tools,
      });
      await editor.isReady;
    } catch (err) {
      // console.log(err);
    }
  };
  const tools = {
    Color: {
      class: window.ColorPlugin,
      config: {
        colorCollections,
        defaultColor,
        type: "text",
        customPicker: true,
      },
    },
    header: { class: Header, inlineToolbar: true },
    Marker: {
      class: window.ColorPlugin,
      config: {
        defaultColor: "#FFBF00",
        type: "marker",
        customPicker: true,
      },
    },
  };

  const isJsonArray = (value) => {
    try {
      return Array.isArray(JSON.parse(value));
    } catch (err) {
      return false;
    }
  };

  const handleEditorTextChange = async (eNode) => {
    try {
      const editorSavedData = await eNode.saver.save();
      const { blocks } = editorSavedData;
      if (onChangeRef.current) {
        onChangeRef.current(JSON.stringify(blocks));
      }
    } catch (err) {
      // console.log(err);
    }
  };
  return (
    <div
      id={holderId}
      style={{
        borderBottom: readOnly || noLine ? "0px" : "1px solid #5c5c5c",
      }}
    />
  );
}
