import Button from "components/CustomButtons/Button";

import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledButton = styled(Button, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, white }) => ({
  fill: white ? "#fff" : "#141314",
  "& svg": {
    margin: "0 2px",
  },
  "&:hover": {
    background: "#0096FF",
    fill: "#fff",
  },
}));
