import { useCallback } from "react";
import { erc721ABI } from "../../configure";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_NFT_APPROVAL_BEGIN,
  FETCH_NFT_APPROVAL_SUCCESS,
  FETCH_NFT_APPROVAL_FAILURE,
} from "./constants";
import { enqueueSnackbar } from "../../common/redux/actions";
import { writeContract } from "@wagmi/core";
import * as Sentry from "@sentry/react";
import _ from "lodash";
export function fetchNFTApproval({ tokenAddress, contractAddress }) {
  return async (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_NFT_APPROVAL_BEGIN,
    });

    try {
      const { hash } = await writeContract({
        address: tokenAddress,
        abi: erc721ABI,
        functionName: "setApprovalForAll",
        args: [contractAddress, true],
      });

      dispatch(
        enqueueSnackbar({
          message: hash,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
          hash,
        })
      );
    } catch (error) {
      dispatch({ type: FETCH_NFT_APPROVAL_FAILURE });
      Sentry.captureException(error);
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "Error"),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
    }
  };
}

export function useFetchNFTApproval() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { fetchNFTApprovalPending } = useSelector((state) => ({
    fetchNFTApprovalPending: state.form.fetchNFTApprovalPending,
  }));

  const boundAction = useCallback(
    (data) => dispatch(fetchNFTApproval(data)),
    [dispatch]
  );

  return {
    fetchNFTApproval: boundAction,
    fetchNFTApprovalPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_NFT_APPROVAL_BEGIN:
      return {
        ...state,
        fetchNFTApprovalPending: true,
      };

    case FETCH_NFT_APPROVAL_SUCCESS:
    case FETCH_NFT_APPROVAL_FAILURE:
      return {
        ...state,
        fetchNFTApprovalPending: false,
      };

    default:
      return state;
  }
}
