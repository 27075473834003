import styled from "styled-components";

export const StyledPopoverContainer = styled.div`
  background: #fff;
  padding: 15px 12px;
`;

export const StyledPopoverHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  & .title {
    font-size: 15px;
    line-height: 18px;
  }

  & .close-icon {
    padding: 0;
    margin-right: 0;
    & img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const StyledLineInPopover = styled.div`
  height: 1px;
  background-color: #a5a5a5;
  width: 411px;
  margin-bottom: 12px;
`;

export const StyledPopoverInfoArea = styled.div`
  margin-bottom: 16px;

  & .single-info-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  & .single-info-item-title {
    color: #555555;
    margin-bottom: 5px;
    font-size: 14px;
  }

  & .single-info-item-role-name {
    width: 150px;
    margin-right: 12px;
  }

  & .single-info-item-role-id {
    width: 220px;
    margin-right: 12px;
  }

  & .single-info-item-role-delete {
    & button {
      height: 20px;
      width: 20px;
      padding: 0;
      margin-right: 0;
      padding-top: 16px;
    }
  }
`;

export const StyledPopoverButtonArea = styled.div`
  display: flex;
  justify-content: space-between;

  & .add-btn {
    width: 150px;
    border: 1px solid #a5a5a5;
    box-shadow: 2.86187px 2.86187px 0.572374px rgba(0, 0, 0, 0.2);
    border-radius: 2.7623px;
    color: #555555;
    font-size: 14px;
    font-weight: 700;
  }

  & .save-btn {
    width: 114px;
    box-shadow: 2.86187px 2.86187px 0.572374px rgba(0, 0, 0, 0.2);
    border-radius: 2.7623px;
    background-color: #0096ff;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    margin-right: 32px;
    text-transform: none;
  }
`;

export const StyledDiscordRoleList = styled.div`
  padding: 8px 10px;

  & .roles-name-title {
    font-size: 10px;
    weight: 300;
    color: #777777;
    margin-bottom: 4px;
  }
`;

export const StyledAddRoleButton = styled.button`
  background-color: #cbe6ff;
  border-radius: 2.5px;
  border: 0;
  width: 129px;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #a5a5a5;
  height: 25px;
`;

export const StyledDiscordRoleSingleItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  & .role-name-input {
    width: 129px;
  }

  & button {
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;
  }
`;

export const StyledDiscordRoleInputContainer = styled.div`
  & :hover {
    cursor: pointer;
  }
  & input {
    padding: 0;
  }
`;

export const StyledFakeInput = styled.div`
  width: 129px;
  border: 1px solid #a5a5a5;
  padding: 5px;
  border-radius: 2.5px;
  background-color: #fff;
  height: 25px;
  display: flex;
  align-items: center;
`;
