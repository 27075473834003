import React from "react";
import _ from "lodash";
import { Outlet } from "react-router-dom";
import CustomListItem from "components/CustomListItem/CustomListItem";
import SideMenu from "components/SideMenu/SideMenu";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { useParams, useNavigate, useLocation } from "react-router-dom";

function CreatorMenuList() {
  const navigate = useNavigate();
  const { auth } = useConnectWallet();
  const location = useLocation();

  const renderDrawerContent = () => {
    return (
      <>
        <CustomListItem
          selected={location.pathname == `/creator`}
          label={
            <div className="startRow">
              <img
                src={require("assets/img/overview.svg").default}
                className="smIcon"
              />
              Workspace
            </div>
          }
          onClick={() => {
            navigate("/creator");
          }}
        />
        <CustomListItem
          selected={location.pathname == `/creator/invited`}
          label={
            <div className="startRow">
              <img
                src={require("assets/img/invited.svg").default}
                className="smIcon"
              />
              Collaboration List
            </div>
          }
          onClick={() => {
            navigate("/creator/invited");
          }}
        />
      </>
    );
  };

  return (
    <div
      style={{
        background: "#F8F8F8",
        minHeight: "calc(100vh - 30px)",
      }}
    >
      {auth && (
        <div style={{ display: "flex" }}>
          <SideMenu content={renderDrawerContent()} />
          <div className="container">
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
}

export default CreatorMenuList;
