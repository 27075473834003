import React from "react";
import TelegramIcon from "@material-ui/icons/Telegram";
import TwitterIcon from "@material-ui/icons/Twitter";
import Button from "components/CustomButtons/Button";
import { StyledButton } from "./socialIcons.styles";
import { ReactComponent as DiscordIcon } from "assets/img/icon_discord_logo.svg";
// import DiscordIcon from "./DiscordIcon";

const SocialIcons = ({ white = false }) => {
  return (
    <div style={{}}>
      <Button
        color={white ? "social" : "socialDark"}
        onClick={() => window.open("http://bit.ly/3uR9OBr")}
      >
        <TelegramIcon />
      </Button>
      <StyledButton
        color={white ? "social" : "socialDark"}
        white={white}
        onClick={() => window.open("https://discord.gg/EVuwAzhKxu")}
      >
        <DiscordIcon />
      </StyledButton>
      <Button
        color={white ? "social" : "socialDark"}
        onClick={() => window.open("https://twitter.com/metacrm_inc")}
      >
        <TwitterIcon />
      </Button>
    </div>
  );
};

export default SocialIcons;
