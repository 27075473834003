import styled from "styled-components";
import Button from "components/CustomButtons/Button.js";

export const StyledRequireRoleArea = styled.div`
  font-size: 12px;
  margin: 3px;
  font-weight: 500;
  margin-bottom: 10px;
  color: ${(props) => (props.color ? props.color : "#383538")};
`;

export const StyledStatusText = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.type === "error" ? "#FF3296" : "#0096FF")};
  margin-top: 15px;

  & svg,
  & img {
    margin-right: 3px;
    height: 16px;
    width: 16px;
  }

  & .status-text {
    line-height: 20px;
    font-weight: 700;
  }
`;

export const StyledJoinButton = styled(Button)`
  color: #0096ff;
  border-color: #0096ff;
  margin-top: 10px;
  font-size: 14px;
  box-shadow: 2.21296px 2.21296px 0.737654px rgba(0, 0, 0, 0.2);
  height: 39px;

  &:hover {
    & .MuiButton-label {
      color: #0096ff;
    }
  }
  & .MuiButton-label {
    color: #0096ff;
  }
`;

export const StyledJoinedOrTry = styled.div`
  & .or {
    color: #777777;
    margin: 4px 10px;
  }

  & .tryBtn {
    font-size: 12px;
  }

  & button {
    margin-left: 0;
  }
`;
