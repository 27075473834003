import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  RESPONSE_LIST_FETCH_BEGIN,
  RESPONSE_LIST_FETCH_SUCCESS,
  RESPONSE_LIST_FETCH_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import { contracts, depositorABI, delegatorABI, apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";
import axios from "axios";
import * as Sentry from "@sentry/react";
export function fetchResponseList() {
  return (dispatch, getState) => {
    dispatch({
      type: RESPONSE_LIST_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const { home } = getState();

        const output = [
          {
            id: "123",
            title: "test",
            desc: "21231",
            created: new Date().getTime(),
          },
          {
            id: "456",
            title: "test2",
            desc: "21231",
            created: new Date().getTime(),
          },
        ];
        dispatch({
          type: RESPONSE_LIST_FETCH_SUCCESS,
          data: output,
        });
      } catch (err) {
        dispatch({
          type: RESPONSE_LIST_FETCH_FAILURE,
        });
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useFetchResponseList() {
  const dispatch = useDispatch();

  const { responseList, fetchResponseListPending, fetchResponseListDone } =
    useSelector(
      (state) => ({
        responseList: state.form.responseList,
        fetchResponseListPending: state.form.fetchResponseListPending,
        fetchResponseListDone: state.form.fetchResponseListDone,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchResponseList(data));
    },
    [dispatch]
  );

  return {
    responseList,
    fetchResponseList: boundAction,
    fetchResponseListPending,
    fetchResponseListDone,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RESPONSE_LIST_FETCH_BEGIN:
      return {
        ...state,
        fetchResponseListPending: true,
      };

    case RESPONSE_LIST_FETCH_SUCCESS:
      return {
        ...state,
        responseList: action.data,
        fetchResponseListPending: false,
        fetchResponseListDone: true,
      };

    case RESPONSE_LIST_FETCH_FAILURE:
      return {
        ...state,
        fetchResponseListPending: false,
      };

    default:
      return state;
  }
}
