import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { Grid } from "@material-ui/core";

import { Link } from "react-router-dom";

export const StyledLinkPrivacyPolicyMobile = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[500],
  bottom: "20px",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  textDecoration: "none",
}));

export const StyledLink = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, computer }) => ({
  color: theme.customColors.white,
  textDecoration: "none",
  marginRight: "24px",
  position: "relative",
  "&:hover": {
    color: theme.customColors.blue[500],
    textDecoration: "underline",
  },
  "&:not(:first-child)": {
    marginLeft: "24px",
  },
  "&:not(:last-child)::after": {
    content: '""',
    position: "absolute",
    right: "-24px",
    top: "50%",
    transform: "translateY(-50%)",
    height: "14px",
    width: "1px",
    backgroundColor: theme.customColors.grey[700],
  },
}));

export const StyledLinkMobile = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, computer }) => ({
  color: theme.customColors.grey[800],
  textDecoration: "none",
  marginRight: "24px",
  position: "relative",
  display: "block",
  "&:hover": {
    color: theme.customColors.blue[500],
    textDecoration: "underline",
  },
  "&:not(:first-child)": {
    marginLeft: "24px",
  },
}));

export const StyledFooterFixedWidthContainer = styled(Grid, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, flexStart }) => ({
  width: "300px",
  whiteSpace: "nowrap",
  display: "flex",
  justifyContent: "flex-end",
  ...(flexStart && { justifyContent: "flex-start" }),
}));
