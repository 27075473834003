import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useFetchCreateList,
  useUpdateSettings,
  useFetchInvite,
} from "features/form/redux/hooks";
import Button from "components/CustomButtons/Button.js";
import { useNavigate, Navigate } from "react-router-dom";
import { useMediaQuery, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Loading } from "features/common/Loading";
import _ from "lodash";
import { useConnectWallet } from "features/home/redux/hooks";
import moment from "moment";
import LandingHeader from "features/home/components/LandingHeader";
import CustomTable from "components/CustomTable/CustomTable";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import RemoveConfirm from "components/RemoveConfirm/RemoveConfirm";
import Pagination from "@mui/material/Pagination";

import InvitePopover from "./invite/InvitePopover";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "white",
    border: "1px solid #F0F0F0",
    boxShadow: "0 1px 5px 2px rgba(50, 50, 50, .1)",
  },
  actionButton: {
    display: "inline-block",
    padding: 2,
    cursor: "pointer",
  },
  content: {
    flexGrow: 1,
    color: "#383538",
    padding: 15,
    marginRight: "20px",
    paddingTop: 60,
  },
  statusRadio: {
    padding: 0,
    margin: "0px 3px",
    marginRight: "1px !important",
  },
  count: {
    color: "#a5a5a5",
    fontSize: 13,
    marginLeft: 5,
  },
}));

export default function CreateFormList({ invited }) {
  const {
    createList,
    creatorTotalCount,
    fetchCreateList,
    fetchCreateListPending,
  } = useFetchCreateList();
  const {
    invitedList,
    fetchInvitedList,
    fetchInvitedListPending,
    invitedTotalCount,
  } = useFetchInvite();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles({ computer });

  const { updateSettings, updateSettingsPending } = useUpdateSettings();
  const navigate = useNavigate();
  const { userData, auth, checkAuth } = useConnectWallet();
  const [anchor, setAnchor] = useState(null);
  const [selectedForm, setSelectedForm] = useState("");
  const address = _.get(userData, "address");
  const [page, setPage] = React.useState(1);
  const [invitedPage, setInvitedPage] = React.useState(1);
  const [invitedRole, setInvitedRole] = React.useState("all");

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleInvitedPageChange = (event, value) => {
    setInvitedPage(value);
  };

  useEffect(() => {
    fetchCreateList({ address, page });
  }, [page]);

  useEffect(() => {
    fetchInvitedList({ page: invitedPage, role: invitedRole });
  }, [invitedPage, invitedRole]);

  if (!checkAuth)
    return (
      <div>
        <LandingHeader />
        <Loading open={true} />
      </div>
    );

  if (!auth) return <Navigate to="/" />;

  const renderRewardField = (row, editable = true) => {
    if (!_.get(row, "reward"))
      return (
        <div className="betweenRow" style={{ height: 36 }}>
          <span style={{ color: "#FC5555", fontWeight: 600, fontSize: 13 }}>
            N/A
          </span>
          {editable && (
            <Button
              color="form"
              onClick={() =>
                navigate("/creator/" + row._id + "/rewardSettings")
              }
            >
              Click to Set
            </Button>
          )}
        </div>
      );
    const statusMapping = {
      rewardSet: 0,
      distributionConfigSet: 1,
      distributed: 2,
    };
    const status = _.get(row, "reward.status");
    if (status == "distributed") {
      return (
        <div
          className="betweenRow"
          style={{
            verticalAlign: "middle",
            minHeight: "36px",
            color: "#0096FF",
            cursor: "pointer",
          }}
        >
          <img
            src={require("assets/img/done.svg").default}
            style={{ width: 61, height: 18 }}
          />

          <Button
            color="form"
            onClick={() =>
              navigate("/creator/" + row._id + "/distributionSettings")
            }
          >
            Check
          </Button>
        </div>
      );
    }
    let value = (statusMapping[status] + 1) * 33;
    return (
      <div style={{ color: "#0096FF", fontSize: 12 }} className="betweenRow">
        <div className="betweenRow" style={{ position: "relative" }}>
          <CircularProgress
            variant="determinate"
            value={100}
            style={{
              position: "absolute",
              left: 0,
              color: "rgba(0,0,0,0.1)",
            }}
          />
          <CircularProgress
            variant="determinate"
            value={value}
            style={{ color: "#0096FF" }}
          />
          <div style={{ position: "absolute", top: 12, left: 8 }}>
            <div style={{ fontSize: 7, fontWeight: 500 }}>{`${Math.round(
              value
            )}%`}</div>
          </div>
        </div>
        <div>
          {editable && (
            <Button
              color="form"
              onClick={() =>
                navigate(
                  "/creator/" +
                    row._id +
                    (statusMapping[status] >= 0
                      ? "/distributionSettings"
                      : "/rewardSettings")
                )
              }
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    );
  };
  const renderRoleName = (role) => {
    let color = "#555555";
    if (role == "viewer") {
      color = "#14C864";
    }
    if (role == "owner") {
      color = "#0096FF";
    }
    return (
      <span style={{ fontWeight: 400, fontSize: 14, color }}>
        {_.upperFirst(role)}
      </span>
    );
  };

  const renderTicketTable = (data, paging, access) => {
    let headers = [
      "Form",
      "Questions",
      "Responses",
      "Cap on Survey #",
      "Created",
      "Reward Status",
      "Setting",
      "Activate",
    ];

    if (access) {
      headers = [
        <CustomDropdown
          white
          data={[
            {
              label: "All",
              value: "all",
            },
            {
              label: "Owner",
              value: "owner",
            },
            {
              label: "Editor",
              value: "editor",
            },
            {
              label: "Viewer",
              value: "viewer",
            },
          ]}
          value={invitedRole}
          onSelect={(e) => {
            setInvitedRole(e.target.value);
          }}
        />,
        ...headers,
      ];
    }
    return (
      <div>
        <CustomTable
          centerText={{ 0: access }}
          style={{ overflow: "hidden" }}
          headers={headers}
          contents={data.map((row) => {
            const role = _.get(row, "userFormAccessRole", "-");
            let rowData = [
              <div className="startRow">
                <img
                  src={row.header.img || require("assets/img/placeholder.png")}
                  style={{
                    width: 76,
                    height: 50,
                    objectFit: "cover",
                    borderRadius: 3,
                    marginRight: 10,
                    verticalAlign: "middle",
                  }}
                  onError={(e) => {
                    e.target.src = require("assets/img/placeholder.png");
                  }}
                />
                <span>
                  <div style={{ fontSize: 14, fontWeight: 700 }}>
                    {_.truncate(_.get(row, "header.title", ""))}
                  </div>
                  <div
                    style={{
                      fontSize: 11,
                      color: "#A4A4A4",
                      fontWeight: 400,
                    }}
                  >
                    {row.startDate &&
                      `From:${moment(row.startDate).format(
                        "YY/MM/DD HH:mm:ss"
                      )}`}
                    {row.endDate && (
                      <div>{`To:${moment(row.endDate).format(
                        "YY/MM/DD HH:mm:ss"
                      )}`}</div>
                    )}
                  </div>
                </span>
              </div>,
              row.questionCount || "0",
              row.responseCount || "0",
              row.responseLimit ? `${row.responseLimit}` : "No",
              <div style={{ fontWeight: 400, fontSize: 12 }}>
                {moment(row.created).format("YY/MM/DD")}
                <div>{moment(row.created).format("HH:mm")}</div>
              </div>,
              <div
                style={{
                  background: "#F8F8F8",
                  padding: 12,
                  minHeight: 60,
                  margin: "6px 0px",
                  width: 185,
                }}
              >
                {_.get(row, "blockchain", "evm") == "evm"
                  ? renderRewardField(row, role !== "viewer")
                  : "N/A"}
              </div>,
              <div style={{}}>
                <div style={{ textAlign: "left" }}>
                  <Button
                    disabled={role == "viewer"}
                    color="form"
                    onClick={() => {
                      navigate("/creator/create/" + row._id);
                    }}
                  >
                    <img
                      src={require("assets/img/edit.svg").default}
                      className="xsIcon"
                    />
                    Edit
                  </Button>

                  <Button
                    color="form"
                    onClick={() => {
                      navigate("/creator/" + row._id + "/preview");
                    }}
                  >
                    <img
                      src={require("assets/img/preview.svg").default}
                      className="xsIcon"
                    />
                    Preview
                  </Button>
                  <Button
                    color="form"
                    onClick={() => {
                      navigate("/creator/" + row._id + "/share");
                    }}
                  >
                    <img
                      src={require("assets/img/share.svg").default}
                      className="xsIcon"
                    />
                    Share
                  </Button>
                </div>
                <div className="startRow" style={{}}>
                  <Button
                    color="form"
                    onClick={() => {
                      navigate("/creator/" + row._id + "/responses");
                    }}
                  >
                    <img
                      src={require("assets/img/responses.svg").default}
                      className="xsIcon"
                    />
                    Responses
                  </Button>

                  <Button
                    color="form"
                    onClick={(e) => {
                      setSelectedForm(row._id);
                      setAnchor(e.currentTarget);
                    }}
                  >
                    <img
                      src={require("assets/img/invite.svg").default}
                      className="xsIcon"
                    />
                    Collaborate
                  </Button>
                  {(!access || role == "owner") && (
                    <RemoveConfirm
                      icon={
                        <img
                          src={require("assets/img/delete.svg").default}
                          style={{
                            height: 24,
                          }}
                        />
                      }
                      onRemove={async () => {
                        await updateSettings({
                          formId: row._id,
                          isDeleted: true,
                          isActive: row.isActive,
                          address,
                        });
                        if (invited) {
                          await fetchInvitedList({
                            page: invitedPage,
                            role: invitedRole,
                          });
                        } else {
                          await fetchCreateList({ address, page });
                        }
                      }}
                      title={"Delete your form"}
                    />
                  )}
                </div>
              </div>,
              <div style={{ textAlign: "center" }}>
                <CustomSwitch
                  onoff={true}
                  disabled={updateSettingsPending || role == "viewer"}
                  checked={_.get(row, "isActive", false)}
                  onChange={async (e) => {
                    await updateSettings({
                      formId: row._id,
                      isActive: e.target.checked,
                      address,
                      isDeleted: row.isDeleted,
                    });
                    if (invited) {
                      fetchInvitedList({
                        page: invitedPage,
                        role: invitedRole,
                      });
                    } else {
                      fetchCreateList({ address, page });
                    }
                  }}
                />
              </div>,
            ];
            if (access) rowData = [renderRoleName(role), ...rowData];
            return rowData;
          })}
        />
        {paging}
      </div>
    );
  };
  const renderContent = () => {
    if (invited)
      return renderTicketTable(
        invitedList,
        <Pagination
          count={Math.ceil(invitedTotalCount / 20)}
          color="primary"
          page={invitedPage}
          onChange={handleInvitedPageChange}
          showFirstButton
          showLastButton
          defaultPage={6}
          boundaryCount={2}
        />,
        true
      );
    return renderTicketTable(
      createList,
      <Pagination
        count={Math.ceil(creatorTotalCount / 20)}
        color="primary"
        page={page}
        onChange={handlePageChange}
        showFirstButton
        showLastButton
        defaultPage={6}
        boundaryCount={2}
      />
    );
  };

  return (
    <div style={{ paddingLeft: 20, paddingTop: 60 }}>
      <Loading
        popover={true}
        open={
          updateSettingsPending ||
          fetchCreateListPending ||
          fetchInvitedListPending
        }
      />

      <InvitePopover
        checkEdit={invited}
        anchor={anchor}
        dataList={invited ? invitedList : createList}
        onClose={() => {
          setAnchor(null);
          setSelectedForm("");
        }}
        formId={selectedForm}
      />
      <div
        className="betweenRow"
        style={{ flexWrap: "wrap", marginBottom: 40 }}
      >
        <div style={{ textAlign: "center", fontSize: 24, fontWeight: 700 }}>
          {invited ? "Invited" : "Workspace"}
          <span className={classes.count}>
            ({invited ? invitedTotalCount : creatorTotalCount})
          </span>
        </div>
        {!invited && (
          <Button
            color="form"
            onClick={() => {
              navigate("/creator/create");
            }}
          >
            <div
              className="betweenRow"
              style={{
                maxWidth: 226,
                padding: "0px 2.5px",
              }}
            >
              <div className="startRow">
                <img
                  src={require("assets/img/form.svg").default}
                  style={{ width: 16, height: 16, margin: 5 }}
                />
                <div
                  style={{ fontSize: 14, fontWeight: 600, paddingRight: 30 }}
                >
                  Create New
                </div>
              </div>

              <AddIcon style={{ fontSize: 22 }} />
            </div>
          </Button>
        )}
      </div>
      {renderContent()}
    </div>
  );
}
