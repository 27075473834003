import React, { useEffect, useState } from "react";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import _ from "lodash";
import Choice from "./Choice";
import File from "./File";
import Text from "./Text";
import Bind from "./Bind";
import Linear from "./Linear";
import Payment from "./Payment";
import Verify from "./Verify";
const QuestionOptions = ({
  questions,
  index,
  setValue,
  answers,
  settings,
  checkAgain,
  renderErrorMessages,
  isPreview,
  blockchain,
}) => {
  const q = questions[index];
  if (!q) return <div />;
  if (settings) {
    return (
      <>
        {q.type == "singleText" && (
          <Text
            questions={questions}
            setValue={setValue}
            index={index}
            settings={true}
            blockchain={blockchain}
          />
        )}
        {q.type == "bind" && (
          <Bind
            questions={questions}
            setValue={setValue}
            index={index}
            checkAgain={checkAgain}
            settings={true}
            renderErrorMessages={renderErrorMessages}
          />
        )}
        {q.type == "verify" && (
          <Verify
            questions={questions}
            setValue={setValue}
            index={index}
            settings={true}
            checkAgain={checkAgain}
            renderErrorMessages={renderErrorMessages}
          />
        )}
        {q.type == "payment" && (
          <Payment
            questions={questions}
            setValue={setValue}
            index={index}
            checkAgain={checkAgain}
            settings={true}
            renderErrorMessages={renderErrorMessages}
          />
        )}
      </>
    );
  }
  if (!answers) {
    return (
      <>
        {q.type == "singleSelect" && (
          <Choice
            questions={questions}
            setValue={setValue}
            index={index}
            checkAgain={checkAgain}
          />
        )}
        {(q.type == "singleText" || q.type == "multipleText") && (
          <Text
            questions={questions}
            setValue={setValue}
            index={index}
            blockchain={blockchain}
          />
        )}
        {q.type == "range" && (
          <Linear questions={questions} setValue={setValue} index={index} />
        )}
        {q.type == "file" && (
          <File questions={questions} setValue={setValue} index={index} />
        )}
        {q.type == "bind" && (
          <Bind questions={questions} setValue={setValue} index={index} />
        )}
        {q.type == "payment" && (
          <Payment
            questions={questions}
            setValue={setValue}
            index={index}
            checkAgain={checkAgain}
          />
        )}
      </>
    );
  }

  return (
    <div>
      {q.type == "singleSelect" && (
        <Choice
          questions={questions}
          setValue={setValue}
          index={index}
          answers={answers}
        />
      )}
      {(q.type == "singleText" ||
        q.type == "multipleText" ||
        q.type == "verify") && (
        <Text
          questions={questions}
          setValue={setValue}
          index={index}
          answers={answers}
        />
      )}
      {q.type == "range" && (
        <Linear
          questions={questions}
          setValue={setValue}
          index={index}
          answers={answers}
        />
      )}

      {q.type == "file" && (
        <File
          questions={questions}
          setValue={setValue}
          index={index}
          answers={answers}
        />
      )}
      {q.type == "bind" && (
        <Bind
          questions={questions}
          setValue={setValue}
          index={index}
          answers={answers}
        />
      )}
      {q.type == "payment" && (
        <Payment
          questions={questions}
          setValue={setValue}
          index={index}
          answers={answers}
          isPreview={isPreview}
        />
      )}
    </div>
  );
};

export default QuestionOptions;
