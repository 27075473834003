import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const StyledResponseRestrictionsEditorContainer = styled.div`
  min-width: 500px;
  max-width: 500px;

  & .card {
    padding: 0;
  }

  & .betweenRow {
    padding: 20px 15px;
  }

  & .setting-content {
    border-top: 1px solid #a5a5a5;
  }
`;

export const StyledRestrictionRowHeader = styled(Grid)`
  color: #a5a5a5;
  font-weight: 700;
  font-size: 12px;
  text-align: left;
  border-top: 1px solid #a5a5a5;
  padding: 7px 12px;

  &:first-child {
    border-top: 0;
  }
`;

export const StyledRestrictionRowContentContainer = styled(Grid)`
  min-height: 50;
  overflow: auto;
  text-align: left;
  padding-right: 4px;
  padding-left: 4px;
`;

export const StyledRestrictionRowContent = styled(Grid)`
  padding-left: 12px;
  padding-right: 12px;
`;

export const StyledSelectToken = styled.div`
  background: white;
  border-radius: 5px;
  border: 1px solid #a5a5a5;
  padding: 3px 5px;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  overflow: hidden;
  margin-top: 5px;

  &.betweenRow {
    padding: 3px 5px;
  }
`;
