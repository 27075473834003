import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";

const MenuItems = ({ style, extra, items }) => {
  return (
    <div style={style}>
      <Hidden smDown>
        <div>
          <Box
            display="flex"
            justifyContent={"flex-start"}
            alignItems={"center"}
            style={{ marginLeft: "-16px" }}
          >
            {extra}
            <ConnectWallet />
          </Box>
        </div>
      </Hidden>
      <Hidden mdUp>
        {items}
        {extra || <ConnectWallet />}
      </Hidden>
    </div>
  );
};

export default MenuItems;
