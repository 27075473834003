import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import ImageUploading from "react-images-uploading";
import ImagePreview from "../components/ImagePreview";
import _ from "lodash";

const File = ({ questions, index, setValue, answers }) => {
  const setQuestionData = (index, label, value) => {
    let newData = [...(answers || questions)];
    _.set(newData, `[${index}].${label}`, value);
    setValue(newData);
  };

  const q = questions[index];
  if (!answers)
    return (
      <div style={{ padding: "15px 0", fontWeight: 900 }}>
        User can upload a (png, jpg, pdf) file.
      </div>
    );

  const max = 5;
  return (
    <ImageUploading
      multiple
      maxNumber={max}
      allowNonImageType={true}
      acceptType={["jpg", "png", "pdf"]}
      value={_.get(answers, `[${index}].file`, [])}
      onChange={(imageList, addUpdateIndex) => {
        let list = imageList;

        if (list.length > 0 && list[list.length - 1].file.size > 10000000) {
          alert("Over Max File Size!");
          return;
        }
        setQuestionData(index, "file", list, q);
      }}
      dataURLKey="data_url"
    >
      {({ imageList, onImageUpload, onImageRemove, dragProps, errors }) => {
        return (
          <div>
            <ImagePreview imgs={imageList} onImageRemove={onImageRemove} />
            {imageList.length < max && (
              <>
                <IconButton
                  onClick={onImageUpload}
                  style={{ padding: 0 }}
                  {...dragProps}
                >
                  <img
                    src={require("assets/img/uploadFilesButton.svg").default}
                  />
                </IconButton>
              </>
            )}
          </div>
        );
      }}
    </ImageUploading>
  );
};

export default File;
