import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "components/CustomButtons/Button";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
export default function RemoveConfirm({ title, text, onRemove, icon }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = (e) => {
    setOpen(false);
    e.stopPropagation();
  };
  return (
    <div>
      <IconButton
        onClick={(e) => {
          setOpen(true);
          e.stopPropagation();
        }}
        style={{ padding: 5 }}
      >
        {icon ? icon : <img src={require("assets/img/ic_trash.svg").default} />}
      </IconButton>
      <Modal open={open} onClick={(e) => handleClose(e)} className="modal">
        <div
          style={{
            maxWidth: 347,
            padding: "48px",
            textAlign: "center",
            borderRadius: 10,
            position: "relative",
            backgroundColor: "#fff",
          }}
        >
          <IconButton
            onClick={(e) => handleClose(e)}
            style={{ position: "absolute", right: 3, top: 3, color: "#383538" }}
          >
            <CloseIcon />
          </IconButton>
          <div
            style={{
              fontSize: 18,
              color: "#383538",
              fontWeight: 700,
            }}
          >
            {title || "Remove"}
          </div>
          <div style={{ padding: "10px 0", fontSize: 14, fontWeight: 500 }}>
            {text || "Do you want to remove this? It can't be undone"}
          </div>

          <div style={{ marginTop: 20 }}>
            <Button
              onClick={(e) => {
                onRemove();
                handleClose(e);
              }}
              color="primary"
              autoFocus
            >
              Confirm Delete
            </Button>
            <div>
              <Button onClick={(e) => handleClose(e)} color="text" sm>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
