import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  CREATE_FORM_BEGIN,
  CREATE_FORM_FAILURE,
  CREATE_FORM_SUCCESS,
  CREAT_LIST_FETCH_SUCCESS,
} from "./constants";
import { enqueueSnackbar } from "features/common/redux/actions";
import { apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";
import axios from "axios";
import _ from "lodash";
import {
  onGenerateKeys,
  onGetEncryptionKey,
  onEncrypt,
  onEncryptText,
} from "features/common/encrypt";
import * as Sentry from "@sentry/react";
import FormData from "form-data";
import Compressor from "compressorjs";
axios.defaults.withCredentials = true;
const covertToFile = async (dataURI, fileName) => {
  if (!dataURI || typeof dataURI != "string") return null;
  if (!dataURI.startsWith("data:image/")) {
    return null;
  }
  const res = await fetch(dataURI, { mode: "no-cors" });
  const buf = await res.arrayBuffer();
  const file = new File([buf], fileName, {
    type: "image/png",
  });
  return file;
};

export function createForm({ formData, questions, address }) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_FORM_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        const { form } = getState();
        // let formKeys = {};
        // let encryptedFormPrivateKey = "";
        // if (!formData.formId) {
        //   formKeys = await onGenerateKeys();
        //   let creatorPublicKey = await onGetEncryptionKey(address);
        //   encryptedFormPrivateKey = await onEncrypt(
        //     creatorPublicKey,
        //     formKeys.private
        //   );
        // }

        const options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        var postFormData = new FormData();
        postFormData.append(
          "header",
          await covertToFile(_.get(formData, "header.img"), "header.png")
        );
        postFormData.append(
          "footer",
          await covertToFile(_.get(formData, "footer.img"), "footer.png")
        );
        let updateQuestions = [];
        let updateOptions = {};
        for (let index = 0; index < questions.length; index++) {
          const file = await covertToFile(
            questions[index].img,
            "Q" + (index + 1).toString() + ".png"
          );
          if (file) {
            updateQuestions.push(index);
            postFormData.append("questions", file);
          }
          let options = _.get(questions, `[${index}].options`);
          if (options && Array.isArray(options)) {
            for (
              let optionIndex = 0;
              optionIndex < options.length;
              optionIndex++
            ) {
              const optionFile = await covertToFile(
                _.get(
                  questions,
                  `[${index}].options[${optionIndex}].img[0].data_url`
                ),
                "Q" +
                  (index + 1).toString() +
                  "_Option" +
                  (optionIndex + 1).toString() +
                  ".png"
              );
              if (optionFile) {
                if (!updateOptions[index]) updateOptions[index] = [];
                updateOptions[index].push(optionIndex);
                postFormData.append("questions", optionFile);
              }
            }
          }
          const q = questions[index];

          if (
            q.type == "bind" &&
            _.get(q, "validation") == "Discord" &&
            !_.get(q, "discordValidation.bindingRole")
          ) {
            _.set(questions[index], `["discordValidation"]["roles"]`, []);
          }
          if (q.type == "payment") {
            _.set(questions[index], `payment.to`, address);
          }
        }
        const uploadResult = await axios.post(
          apiUrl + "api/gcp/upload",
          postFormData,
          options
        );
        const header = {
          ...formData.header,
          img: _.get(
            uploadResult.data,
            "header",
            _.get(formData, "header.img")
          ),
        };
        const footer = {
          ...formData.footer,
          img: _.get(
            uploadResult.data,
            "footer",
            _.get(formData, "footer.img")
          ),
        };
        const questionImages = _.get(uploadResult.data, "questions");
        let imgIndex = 0;

        const formatQuestion = _.cloneDeep(questions);
        for (let i = 0; i < questions.length; i++) {
          if (_.includes(updateQuestions, i)) {
            formatQuestion[i].img = _.nth(questionImages, imgIndex);
            imgIndex++;
          }
          let options = _.get(questions, `[${i}].options`);
          if (options && Array.isArray(options)) {
            for (
              let optionIndex = 0;
              optionIndex < options.length;
              optionIndex++
            ) {
              if (_.includes(updateOptions[i], optionIndex)) {
                formatQuestion[i].options[optionIndex].img = _.nth(
                  questionImages,
                  imgIndex
                );
                imgIndex++;
              }
            }
          }
        }
        let body = {
          walletAddress: address,
          startDate:
            formData.startDate && new Date(formData.startDate).getTime(),
          endDate: formData.endDate && new Date(formData.endDate).getTime(),
          header,
          footer,
          reward: _.get(formData, "reward"),

          // form_public_key: formKeys.public,
          // form_encrypted_private_key: encryptedFormPrivateKey,
          questions: formatQuestion,
          isActive: true,
        };

        if (formData.formId) {
          body.formId = formData.formId;
        } else {
          // body.form_public_key = formKeys.public;
          // body.form_encrypted_private_key = encryptedFormPrivateKey;
        }
        if (_.get(formData, "chainId")) body.chainId = formData.chainId;
        if (
          _.get(formData, "isRestrictionsRequired") == true &&
          formData.restrictions.length > 0
        ) {
          body.restrictions = formData.restrictions;
          body.restrictionLogic = formData.restrictionLogic;
        } else {
          body.restrictions = [];
        }
        if (_.get(formData, "reward.hasReward") == true) {
          body.reward = formData.reward;
        } else {
          body.reward = { isDelete: true };
        }
        if (_.get(formData, "responseLimit")) {
          body.responseLimit = formData.responseLimit;
        }
        const result = await axios.post(apiUrl + "api/forms/update", body);

        dispatch(
          enqueueSnackbar({
            message:
              (formData.formId ? "Update" : "Create") +
              " Success : " +
              result.data._id,
            options: {
              variant: "success",
            },
          })
        );
        dispatch({
          type: CREATE_FORM_SUCCESS,
          data: result.data._id,
        });
        dispatch({
          type: CREAT_LIST_FETCH_SUCCESS,
          data: [
            { ...result.data, questionCount: questions.length },
            ...form.createList,
          ],
        });
        resolve(result.data._id);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );

        dispatch({
          type: CREATE_FORM_FAILURE,
        });
      }
    });

    return promise;
  };
}

export function useCreateForm() {
  const dispatch = useDispatch();

  const { createdFormId, createFormPending } = useSelector(
    (state) => ({
      createdFormId: state.form.createdFormId,
      createFormPending: state.form.createFormPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(createForm(data));
    },
    [dispatch]
  );

  return {
    createdFormId,
    createForm: boundAction,
    createFormPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATE_FORM_BEGIN:
      return {
        ...state,
        createFormPending: true,
      };

    case CREATE_FORM_SUCCESS:
      return {
        ...state,
        createdFormId: action.data,
        createFormPending: false,
      };

    case CREATE_FORM_FAILURE:
      return {
        ...state,
        createFormPending: false,
      };

    default:
      return state;
  }
}
