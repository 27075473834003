import styled from "styled-components";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/Tabs";

export const StyledPopoverContainer = styled.div`
  background: #fff;
  padding: 16px;
`;

export const StyledPopoverHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  & .title {
    font-size: 15px;
    line-height: 18px;
  }

  & .close-icon {
    padding: 0;
    margin-right: 0;
    & img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const StyledRewardPopoverContent = styled.div``;
