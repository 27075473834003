import styled from "styled-components";

export const StyledConnectSuccess = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  color: #383538;
  & img {
    width: 85px;
    height: 85px;
    margin-bottom: 25px;
  }

  & h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 0;
  }

  & .text-content {
    & p {
      margin: 0;
      font-size: 12px;
      font-weight: 700;
    }
  }
`;
