import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledFooterEmail = styled("a", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, computer = true }) => ({
  textDecoration: "none",
  color: computer ? "#fff" : "#383538",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  marginRight: computer ? "24px" : 0,
  "&:hover": {
    color: "#0096ff",
    textDecoration: "underline",
    "& svg": {
      fill: "#0096ff",
    },
  },
  "& svg": {
    marginRight: "8px",
    fill: computer ? "#fff" : "#383538",
  },
  ...(computer && {
    "&::after": {
      content: '""',
      position: "absolute",
      right: "-24px",
      top: "50%",
      transform: "translateY(-50%)",
      height: "14px",
      width: "1px",
      backgroundColor: theme.customColors.grey[700],
    },
  }),

  ...(!computer && { marginBottom: "10px" }),
}));
