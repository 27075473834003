import { useCallback } from "react";
import { erc20ABI, distributeABI } from "../../configure";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_DISTRIBUTE_BEGIN,
  FETCH_DISTRIBUTE_SUCCESS,
  FETCH_DISTRIBUTE_FAILURE,
} from "./constants";
import { enqueueSnackbar } from "../../common/redux/actions";
import { ethers } from "ethers";
import _ from "lodash";
import { convertAmountFromNumber } from "features/helpers/bignumber";
import { fetchFormData } from "./fetchFormData";
import { fetchCreateList } from "./fetchCreateList";
import { updateReward } from "./updateReward";
import * as Sentry from "@sentry/react";
import { writeContract } from "@wagmi/core";
export function fetchDistribute({
  totalRewardAmount,
  addresses,
  value,
  tokenAddress,
  contractAddress,
  formId,
  address,
}) {
  return async (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_DISTRIBUTE_BEGIN,
    });

    let values = _.map(addresses, (d) => {
      return value;
    });

    try {
      let tx;

      if (totalRewardAmount) {
        tx = await writeContract({
          address: contractAddress,
          abi: distributeABI,
          functionName: "distributeRewardEther",
          args: [
            addresses,
            values,
            {
              value: totalRewardAmount,
            },
          ],
        });
      } else {
        tx = await writeContract({
          address: contractAddress,
          abi: distributeABI,
          functionName: "distributeRewardToken",
          args: [tokenAddress, addresses, values],
        });
      }
      let hash = _.get(tx, "hash");
      dispatch(
        enqueueSnackbar({
          message: hash,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
          },
          hash,
        })
      );

      let txResult = await tx.wait();

      if (txResult.status == 1) {
        dispatch(
          fetchFormData({
            formId,
          })
        );
        dispatch(
          fetchCreateList({
            address,
          })
        );

        dispatch(
          updateReward({
            formId,
            reward: {
              distributeTx: tx.hash,
              distributedData: [{ txHash: tx.hash, addresses }],
            },
            address,
          })
        );

        dispatch({
          type: FETCH_DISTRIBUTE_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_DISTRIBUTE_FAILURE });
      Sentry.captureException(error);
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "Error"),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        })
      );
    }
  };
}

export function useFetchDistribute() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { fetchDistributePending } = useSelector((state) => ({
    fetchDistributePending: state.form.fetchDistributePending,
  }));

  const boundAction = useCallback(
    (data) => dispatch(fetchDistribute(data)),
    [dispatch]
  );

  return {
    fetchDistribute: boundAction,
    fetchDistributePending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_DISTRIBUTE_BEGIN:
      return {
        ...state,
        fetchDistributePending: true,
      };

    case FETCH_DISTRIBUTE_SUCCESS:
    case FETCH_DISTRIBUTE_FAILURE:
      return {
        ...state,
        fetchDistributePending: false,
      };

    default:
      return state;
  }
}
