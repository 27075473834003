import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import { useConnectWallet } from "features/home/redux/hooks";
import { Grid, Container, Modal } from "@material-ui/core";
import LandingHeader from "./components/LandingHeader";
import { Link } from "react-router-dom";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import { useDisconnect } from "wagmi";
const useStyles = makeStyles((theme) => ({}));

const SelectUser = () => {
  const classes = useStyles();
  const buttonCSS = {
    borderRadius: 20,
    padding: 20,
    margin: 20,
    minWidth: 150,
    border: "1px solid #E6E6E6",
  };

  return (
    <>
      <LandingHeader />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ height: `calc(100vh - 65px)`, textAlign: "center" }}
      >
        <Grid item>
          <h2>
            Hello, welcome to
            <img
              src={require("assets/img/title.svg").default}
              style={{ marginLeft: 10, height: 30, verticalAlign: "middle" }}
            />
          </h2>
          <div style={{ marginTop: "20px", color: "#459BFF", fontWeight: 500 }}>
            What do you want to do?
          </div>
          <div>
            <ConnectWallet chooseUser="user" />
            <ConnectWallet chooseUser="creator" />

          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectUser;
