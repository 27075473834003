import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  CREATOR_RESPONSE_FETCH_BEGIN,
  CREATOR_RESPONSE_FETCH_SUCCESS,
  CREATOR_RESPONSE_FETCH_FAILURE,
} from "./constants";
import { apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";
import axios from "axios";
import {
  onGenerateKeys,
  onGetEncryptionKey,
  onEncrypt,
  onEncryptText,
  onDecrypt,
  onDecryptText,
} from "features/common/encrypt";
import _ from "lodash";
import * as Sentry from "@sentry/react";
axios.defaults.withCredentials = true;

export function fetchCreatorResponseData({
  formId,
  address,
  filterZero = 0,
  limit = 20,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATOR_RESPONSE_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const { home } = getState();
        const result = await axios.get(
          apiUrl +
            `api/forms/${formId}/responses?filterZero=${filterZero}&limit=${limit}`
        );
        let output = result.data;
        dispatch({
          type: CREATOR_RESPONSE_FETCH_SUCCESS,
          data: output,
          formId,
        });
      } catch (err) {
        dispatch({
          type: CREATOR_RESPONSE_FETCH_FAILURE,
        });
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useFetchCreatorResponseData() {
  const dispatch = useDispatch();

  const { creatorResponseData, fetchCreatorResponseDataPending } = useSelector(
    (state) => ({
      creatorResponseData: state.form.creatorResponseData,
      fetchCreatorResponseDataPending:
        state.form.fetchCreatorResponseDataPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchCreatorResponseData(data));
    },
    [dispatch]
  );

  return {
    creatorResponseData,
    fetchCreatorResponseData: boundAction,
    fetchCreatorResponseDataPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATOR_RESPONSE_FETCH_BEGIN:
      return {
        ...state,
        fetchCreatorResponseDataPending: true,
      };

    case CREATOR_RESPONSE_FETCH_SUCCESS:
      return {
        ...state,
        creatorResponseData: {
          ...state.creatorResponseData,
          [action.formId]: action.data,
        },
        fetchCreatorResponseDataPending: false,
      };

    case CREATOR_RESPONSE_FETCH_FAILURE:
      return {
        ...state,
        fetchCreatorResponseDataPending: false,
      };

    default:
      return state;
  }
}
