import React from "react";
import DateTimePicker from "./DateTimePicker";
import { format } from "date-fns";

import { StyledDateTimePickerContainer } from "./CustomDateTimePicker.styles";

const CustomDateTimePicker = ({ renderIconBtn, ...otherProps }) => {
  const handleDate = (value) => {
    if (!value) {
      return;
    }
    return format(value, "yyyy MMM dd HH:mm");
  };

  return (
    <StyledDateTimePickerContainer>
      <DateTimePicker customFormat={handleDate} {...otherProps} />
      {renderIconBtn()}
    </StyledDateTimePickerContainer>
  );
};

export default CustomDateTimePicker;
