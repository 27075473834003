import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Switch, Checkbox } from "@material-ui/core";
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 14,
    padding: 0,
    overflow: "visible",
    // display: 'flex',
  },

  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(18px)",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#98C7FF",
        borderColor: "#98C7FF",
      },
    },
    "&.Mui-disabled": {
      "& + $track": {
        opacity: 1,
        backgroundColor: "#CCCCCC",
        borderColor: "#CCCCCC",
      },
    },
  },
  colorSecondary: {
    padding: 0,
    backgroundColor: "#F9F9F9",
    border: "3px solid #4D9FFF",
    marginTop: "-4px",
    boxShadow: "0px 0px 3.21333px rgba(39, 39, 39, 0.35)",
    "&.Mui-disabled": {
      color: "#ffffff",
      border: "3px solid #4D9FFF",
      backgroundColor: "#ffffff",
    },
    "&$checked": {
      color: "#4D9FFF",
      border: "3px solid #F9F9F9",
      backgroundColor: "#4D9FFF",
      "&.Mui-disabled": {
        color: "#4D9FFF",
        border: "3px solid #F9F9F9",
        backgroundColor: "#4D9FFF",
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: "#CCCCCC",
  },
  checked: {},
}))(Switch);
export default function CustomSwitch({ onoff, ...props }) {
  if (onoff) {
    return (
      <Checkbox
        disableRipple={true}
        icon={<img src={require("assets/img/switchOff.svg").default} />}
        checkedIcon={<img src={require("assets/img/switchOn.svg").default} />}
        {...props}
      />
    );
  }
  return <AntSwitch {...props}></AntSwitch>;
}
