import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  RESPONSE_FETCH_BEGIN,
  RESPONSE_FETCH_SUCCESS,
  RESPONSE_FETCH_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import { contracts, depositorABI, delegatorABI, apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";
import axios from "axios";
import * as Sentry from "@sentry/react";
axios.defaults.withCredentials = true;
export function fetchResponseData() {
  return (dispatch, getState) => {
    dispatch({
      type: RESPONSE_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const { home } = getState();
        let output = {};

        dispatch({
          type: RESPONSE_FETCH_SUCCESS,
          data: output,
        });
      } catch (err) {
        dispatch({
          type: RESPONSE_FETCH_FAILURE,
        });
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useFetchResponseData() {
  const dispatch = useDispatch();

  const { responseData, fetchResponseDataPending } = useSelector(
    (state) => ({
      responseData: state.form.responseData,
      fetchResponseDataPending: state.form.fetchResponseDataPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchResponseData(data));
    },
    [dispatch]
  );

  return {
    responseData,
    fetchResponseData: boundAction,
    fetchResponseDataPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RESPONSE_FETCH_BEGIN:
      return {
        ...state,
        responseData: {},
        fetchResponseDataPending: true,
      };

    case RESPONSE_FETCH_SUCCESS:
      return {
        ...state,
        responseData: action.data,
        fetchResponseDataPending: false,
      };

    case RESPONSE_FETCH_FAILURE:
      return {
        ...state,
        fetchResponseDataPending: false,
      };

    default:
      return state;
  }
}
