export const contracts = {
  distribute: {
    137: "0xf2ba5952c5368a660d08323797ed7268d87dd7b3",
    1: "0xf2ba5952c5368a660d08323797ed7268d87dd7b3",
    1337: "0xf2ba5952c5368a660d08323797ed7268d87dd7b3",
    56: "0xf2ba5952c5368a660d08323797ed7268d87dd7b3",
    42161: "0xf2ba5952c5368a660d08323797ed7268d87dd7b3",
    10: "0xf2ba5952c5368a660d08323797ed7268d87dd7b3",
    7001: "0xF2ba5952C5368a660D08323797ed7268d87dd7b3",
    43114: "0xF2ba5952C5368a660D08323797ed7268d87dd7b3",
    108: "0xA366179F6Db7018e989523FD9cA8cd7aC28b52e3",
  },
  distributeNFT: {
    137: "0x718A99d7A98729E456e90162Ffe3a1B59Ff73B74",
    1: "0x718A99d7A98729E456e90162Ffe3a1B59Ff73B74",
    1337: "0x718A99d7A98729E456e90162Ffe3a1B59Ff73B74",
    56: "0x718A99d7A98729E456e90162Ffe3a1B59Ff73B74",
    42161: "0x718A99d7A98729E456e90162Ffe3a1B59Ff73B74",
    10: "0x718A99d7A98729E456e90162Ffe3a1B59Ff73B74",
    7001: "0x718A99d7A98729E456e90162Ffe3a1B59Ff73B74",
    43114: "0x718A99d7A98729E456e90162Ffe3a1B59Ff73B74",
    108: "0x042a0C774873E1DCDd58F76f26b3252EdFE6c6a6",
  },
};
