import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useConnectWallet, useUserSelect } from "features/home/redux/hooks";
import _ from "lodash";

const Logout = () => {
  const { auth, signout } = useConnectWallet();
  const { user, userSelect } = useUserSelect();
  useEffect(() => {
    signout();
  }, []);
  // if (!auth) return <Navigate to={"/" + (user || "user") + "/login"} />;
  if (!auth) return <Navigate to={"/"} />;

  return <div />;
};

export default Logout;
