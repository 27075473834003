import React from "react";
import { StyledFooterEmail } from "./Footer.styles";

const FooterEmail = ({ computer, Icon }) => {
  return (
    <StyledFooterEmail computer={computer} href="mailto: bd@metacrm.inc">
      {Icon && <Icon />}
      <div>bd@metacrm.inc</div>
    </StyledFooterEmail>
  );
};

export default FooterEmail;
