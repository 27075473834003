import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useConnectWallet } from "features/home/redux/hooks";
import { Grid } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import LandingOurPartners from "../components/LandingOurPartners/LandingOurPartners";

const useStyles = makeStyles((theme) => ({
  landingHeaderBg: {
    textAlign: "left",
    color: "#383538",
    backgroundSize: "cover",
    padding: "0 5vw",
    position: "relative",
  },
  cardHeader: {
    fontSize: (props) => (props.computer ? "40px" : "30px"),
    textAlign: (props) => (props.computer ? "left" : "center"),
    fontWeight: 700,
  },
  cardDesc: {
    fontSize: (props) => (props.computer ? "21px" : "16px"),
    textAlign: (props) => (props.computer ? "left" : "center"),
    margin: "14px 0",
    fontWeight: 500,
    lineHeight: "25px",
    wordBreak: "keep-all",
    marginBottom: 24,
  },
  cardBg: {
    zIndex: -1,
    transform: "matrix(-1, 0, 0, 1, 0, 0)",
    position: "absolute",
    height: (props) => (props.computer ? 432 : 497),
    maxWidth: 1400,
    width: (props) => (props.computer ? "170%" : "200%"),
    top: (props) => (props.computer ? "-50px" : "-10px"),
    borderRadius: (props) =>
      props.computer ? "400px 250px 250px 0px" : "0px 200px 200px 0px",
  },
  cardImg: {
    width: "100%",
    maxWidth: 700,
    minHeight: (props) => (props.computer ? 400 : 150),
    objectFit: "contain",
    padding: (props) => (props.computer ? "0 24px" : "0 40px"),
  },
  tabButton: {
    backgroundColor: "transparent",
    "& .MuiButton-label": {
      color: `#ffffff`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
    },
    border: 0,
    fontWeight: 700,
    marginRight: 20,
  },
}));
const demoUrl = "https://alpha.metaform.xyz/user/633ba1665d7cde34a5658167";
const partners = [
  "parner_ic_1",
  "parner_ic_2",
  "parner_ic_3",
  "parner_ic_4",
  "parner_ic_5",
  "parner_ic_6",
  "parner_ic_7",
  "parner_ic_8",
  "parner_ic_9",
  "parner_ic_10",
  "parner_ic_11",
  "parner_ic_12",
  "parner_ic_13",
  "parner_ic_14",
  "parner_ic_15",
  "parner_ic_16",
  "parner_ic_17",
  "parner_ic_18",
  "parner_ic_19",
  "parner_ic_20",
  "parner_ic_21",
  "parner_ic_22",
  "parner_ic_23",
  "parner_ic_24",
  "parner_ic_25",
  "parner_ic_26",
  "parner_ic_27",
  "parner_ic_28",
  "parner_ic_29",
  "parner_ic_30",
  "parner_ic_31",
  "parner_ic_32",
  "parner_ic_33",
  "parner_ic_34",
  "parner_ic_35",
  "parner_ic_36",
  "parner_ic_37",
  "parner_ic_38",
  "parner_ic_39",
  "parner_ic_40",
  "parner_ic_41",
  "parner_ic_42",
];

const partnerSwipe = [
  {
    partnerLogo: require("assets/img/ic_radiant.svg").default,
    content: `MetaDesk’s innovative customer ticketing aggregator, combined with their tailored on-chain data, has transformed Radiant Capital’s customer support experience into a best-in-class service. By attentively addressing our unique needs, MetaDesk has empowered us to seamlessly integrate analytics from our multi-chain infrastructure and elevate our community engagement.`,
    partnerTitle: "Operations Manager",
    partnerName: "Liam",
    id: 1,
  },
  {
    partnerLogo: require("assets/img/ic_curve.svg").default,
    content: `MetaDask was not only able to paint a clear picture of each user’s profile, but also capable of tiering users that allowed us to prioritize important users and serve each tier of users with the appropriate best practices. MetaCRM help improve customer/user experience of DEX to next level, giving us an edge to better serve our users.`,
    partnerTitle: "",
    partnerName: "",
    id: 2,
  },
  {
    partnerLogo: require("assets/img/ic_zetachain.svg").default,
    content: `ZetaChain is really enthusiastic about MetaCRM’s product suite and company vision. MetaForm continues to play an important role in our global community for decentralized surveys, and MetaCRM is pushing boundaries around web3 ticketing and user analytics. The team is really hands-on, responsive, and we’re excited to continue developing a strategic partnership.`,
    partnerTitle: "Head of Community at ZetaChain",
    partnerName: "Jonathan Covey",
    id: 5,
  },
  {
    partnerLogo: require("assets/img/ic_zeeverse.svg").default,
    content: `MetaCRM is a game changer! Their solution enables us to easily collect real feedback, manage & communicate users, and even be able to let us respond with a third of the time previously required because having visibility of user interaction history in one screen.`,
    partnerTitle: "CEO",
    partnerName: "Edgars Vecozolins ",
    id: 3,
  },
  {
    partnerLogo: require("assets/img/ic_magpie.svg").default,
    content: `The integration and onboarding process went much smoother than expected because the MetaCRM team are truly crypto veterans who know exactly what DeFi needs for CRM. They always deliver fast and attend to small details. Superb execution overall.`,
    partnerTitle: "Project Lead",
    partnerName: "Grimmace",
    id: 4,
  },
];

const Home = () => {
  const navigate = useNavigate();
  const { auth } = useConnectWallet();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles({ computer });
  const headerDeco = {
    width: computer ? 60 : 40,
    height: computer ? 60 : 40,
    opacity: 0.5,
    transform: computer ? "" : "rotate(90deg)",
  };

  const renderCreateSurveyButton = (label, type) => {
    let imageObj =
      type == "1"
        ? require("assets/img/createSurvey.svg").default
        : type == "2"
        ? require("assets/img/joinNow.svg").default
        : require("assets/img/joinNow2.svg").default;

    return (
      <div>
        <div
          style={{ display: auth ? "none" : "", marginTop: computer ? 0 : 20 }}
        >
          <ConnectWallet buttonImg={imageObj}></ConnectWallet>
        </div>
        <div
          style={{ display: auth ? "" : "none", marginTop: computer ? 0 : 20 }}
        >
          <div
            style={{
              padding: 0,
              marginBottom: 30,
              marginTop: 40,
              cursor: "pointer",
            }}
            onClick={() => {
              if (auth) {
                navigate("/creator/create");
              }
            }}
          >
            <img
              src={imageObj}
              style={{
                maxWidth: "60vw",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderConnectHeader = (header, style) => {
    return (
      <div className={computer ? "row" : "column"}>
        <img
          src={require("assets/img/connectHeaderLeft.svg").default}
          style={headerDeco}
        />
        <div
          style={{
            fontSize: computer ? 32 : 16,
            fontWeight: 700,
            lineHeight: "150%",
            color: "#141314",
            textAlign: "center",
            padding: computer ? 0 : "5px 24px",
            ...style,
          }}
        >
          {header}
        </div>
        <img
          src={require("assets/img/connectHeaderRight.svg").default}
          style={headerDeco}
        />
      </div>
    );
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classes.landingHeaderBg}
        style={{
          alignContent: "center",
          height: computer ? "100vh" : "80vh",
          backgroundImage: `url(${
            require("assets/img/" +
              (computer ? "landingHeader.svg" : "landingHeaderMobile.svg"))
              .default
          })`,
        }}
      >
        <Grid item xs={12} md={6}>
          <div style={{ textAlign: "center", marginTop: "10vh" }}>
            <div>
              {computer ? (
                <img
                  src={require("assets/img/metaformHeader.svg").default}
                  style={{ maxWidth: 590, width: "90%" }}
                />
              ) : (
                <img
                  src={require("assets/img/metaformHeader_m.svg").default}
                  style={{ width: "50%" }}
                />
              )}
            </div>

            <div style={{ margin: computer ? 0 : "24px 0px 50px" }}>
              <a
                style={{
                  padding: 0,
                  marginLeft: 10,
                }}
                href={demoUrl}
                target="_blank"
                role="button"
                rel="noreferrer"
              >
                <img
                  src={require("assets/img/scheduleDemo.svg").default}
                  style={{
                    maxWidth: "60vw",
                    borderRadius: 10,
                  }}
                />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={require("assets/img/headerImage.svg").default}
            style={{
              width: "100%",
              maxWidth: 630,
            }}
          />
        </Grid>
      </Grid>

      <div
        style={{
          zIndex: 500,
          maxWidth: 1400,
          margin: "0 auto",
          position: "relative",
          padding: computer ? 50 : 10,
          textAlign: "center",
        }}
      >
        <div
          style={{
            height: 266,
            textAlign: "center",
            padding: "90px 10px",
          }}
        >
          {renderConnectHeader(
            <div
              style={{
                fontSize: computer ? 34 : 16,
              }}
            >
              More than{" "}
              <span
                style={{
                  fontSize: computer ? 41 : 16,
                  color: "#0096FF",
                }}
              >
                95%
              </span>{" "}
              of our clients rank MetaForm the #1 tool to acquire insightful
              data from Web3 users
            </div>
          )}
        </div>
        <Grid container alignItems="center" style={{ margin: "60px auto" }}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                textAlign: "left",
                position: "relative",
              }}
            >
              <div
                className={classes.cardBg}
                style={{
                  background: "#FFF4D0",
                  left: computer ? 0 : "-10vw",
                }}
              />
              <div
                style={{
                  maxWidth: 543,
                  margin: "0px auto",
                  paddingLeft: computer ? 60 : 20,
                  paddingRight: computer ? 0 : 20,
                  paddingTop: computer ? 50 : 10,
                }}
              >
                <div className={classes.cardHeader}>
                  Informative On-Chain Analytics
                </div>
                <div className={classes.cardDesc}>
                  Utilize MetaForm's on-chain data analytics tool to derive
                  customer insights
                </div>

                {computer && renderCreateSurveyButton("landing_4", "2")}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingTop: computer ? 60 : 10 }}>
            <img
              className={classes.cardImg}
              src={require("assets/img/landing_userAnalysis.svg").default}
            />
            {!computer && renderCreateSurveyButton("landing_4", "2")}
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          style={{ margin: "120px auto" }}
          direction="row-reverse"
        >
          <Grid item xs={12} md={6}>
            <div
              style={{
                textAlign: "left",
                position: "relative",
              }}
            >
              <div
                className={classes.cardBg}
                style={{
                  background: "#FFD6F8",
                  borderRadius: computer
                    ? "250px 400px 0px 250px"
                    : "200px 0 0 200px",
                  right: computer ? 0 : "-10vw",
                }}
              />
              <div
                style={{
                  maxWidth: 543,
                  margin: "0px auto",
                  paddingLeft: computer ? 60 : 20,
                  paddingRight: computer ? 20 : 20,
                  paddingTop: computer ? 30 : 10,
                }}
              >
                <div className={classes.cardHeader}>
                  Seamless On-Chain Survey Tool
                </div>
                <div className={classes.cardDesc}>
                  Having a hard time filtering and integrating results from your
                  surveys?
                  <br />
                  <br />
                  MetaForm is a web3 solution that connects to multiple
                  prominent blockchains
                </div>
                {computer && renderCreateSurveyButton("landing_5", "3")}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingTop: computer ? 20 : 0 }}>
            <img
              className={classes.cardImg}
              src={require("assets/img/landing_bg3-1.svg").default}
            />
            {!computer && renderCreateSurveyButton("landing_5", "3")}
          </Grid>
        </Grid>

        <Grid container alignItems="center" style={{ margin: "120px auto" }}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                textAlign: "left",
                position: "relative",
              }}
            >
              <div
                className={classes.cardBg}
                style={{
                  background: "#CCEAFF",
                  left: computer ? 0 : "-10vw",
                }}
              />
              <div
                style={{
                  maxWidth: 543,
                  margin: "0px auto",
                  paddingLeft: computer ? "60px" : 20,
                  paddingRight: computer ? 0 : 20,
                  paddingTop: computer ? 30 : 10,
                }}
              >
                <div className={classes.cardHeader}>
                  Cross-Chain
                  <br /> Survey-To-Earn
                </div>
                <div className={classes.cardDesc}>
                  Want to incentivize your users?
                  <br />
                  <br />
                  With MetaForm, you can implement cross-chain survey-to-earn.
                  For example, "Complete the survey to receive $10 USDC on
                  Arbitrum."
                </div>

                {computer && renderCreateSurveyButton("landing_6", "2")}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              paddingRight: computer ? 50 : 0,
            }}
          >
            <img
              className={classes.cardImg}
              src={require("assets/img/landing_surveyToEarn.svg").default}
            />
            {!computer && renderCreateSurveyButton("landing_6", "2")}
          </Grid>
        </Grid>
      </div>
      <LandingOurPartners
        computer={computer}
        partnerSwipeInfo={partnerSwipe}
        partnersIcon={partners}
      />
    </>
  );
};

export default Home;
