import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
const useStyles = makeStyles((theme) => ({
  field: {
    padding: "4px 0",
    color: "white",
    display: "block",
    backgroundColor: "transparent",
    overflow: "visible",
    // textAlign: "center",
    position: "relative",
  },
  inputRoot: {
    color: "white",
  },
  showDetail: {
    justifyContent: "space-around",
    width: "100%",
    color: "#383538",
    borderBottom: `1px solid #141314`,
    backgroundColor: "transparent",
    fontWeight: 600,
    "& .MuiInputBase-root": {
      color: "#383538",
      fontSize: 15,
      FontFamily: "Inter",
      fontWeight: 600,
    },
    "& fieldset": { color: "#383538" },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "transparent",
      color: "#383538",
      border: `none`,
      "& fieldset": {
        border: `none`,
      },
    },
    outline: "none",
  },
  center: {
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  option: {
    marginTop: 8,
    "& .MuiInputBase-root": {
      color: "#383538",
    },
  },

  hovered: {
    "& fieldset": {},
  },
  focused: {
    "& fieldset": {},
  },
  number: {
    background: "#FFFFFF",
    borderRadius: 5,
    border: "1px solid #A5A5A5",
    padding: "5px 10px",
  },
  noLine: {
    border: "0px",
  },
  sm: {
    padding: "2px 5px",
    "& .MuiInputBase-root": {
      fontSize: 13,
    },
  },
  outline: {
    border: "1px solid #a5a5a5",
    borderRadius: 5,
    padding: "5px 10px",
    boxSizing: "border-box",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, maxCount, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowEmptyFormatting={true}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      isAllowed={({ floatValue }) =>
        maxCount ? !floatValue || floatValue <= maxCount : true
      }
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

function AutoSizeCustom(props) {
  return (
    <div style={{ width: "100%" }}>
      <TextareaAutosize {...props} />
    </div>
  );
}

export default function CustomOutlinedInput(props) {
  const {
    classes,
    disabled,
    stillShow = false,
    value,
    placeholder,
    center,
    option,
    onChange,
    outline,
    startAdornment,
    sm,
    endAdornment,
    onBlur,
    blurSideEffectOnChange = true,
    noLine,
    number,
    max,
    background,
    containerStyle,
    multiline = false,
    ...otherProps
  } = props;
  const commonStyle = useStyles();
  const commonClasses = {
    root: `${commonStyle.showDetail} ${center && commonStyle.center} ${
      sm && commonStyle.sm
    } ${option && commonStyle.option} ${number && commonStyle.number} ${
      noLine && commonStyle.noLine
    } ${outline && commonStyle.outline}`,
    disabled: commonStyle.disabled,
  };

  if (disabled && !stillShow) {
    return (
      <div style={{ textAlign: center ? "center" : "left" }}>
        <div
          style={{
            fontSize: sm ? 12 : 18,
            paddingLeft: 5,
            paddingTop: 5,
            fontWeight: 700,
            color: option ? "#383538" : "#383538",
          }}
        >
          {value}
        </div>
      </div>
    );
  }
  let inputProps = {
    startAdornment: props.startAdornment,
    disableUnderline: true,
    endAdornment: endAdornment && (
      <InputAdornment position="end" className={commonStyle.inputAdornment}>
        {endAdornment}
      </InputAdornment>
    ),
  };
  if (startAdornment) inputProps["startAdornment"] = startAdornment;
  if (number) {
    inputProps["inputComponent"] = NumberFormatCustom;
    inputProps["inputProps"] = { ...otherProps };
  }
  if (multiline) inputProps["inputComponent"] = AutoSizeCustom;
  return (
    <div
      className={commonStyle.field}
      style={{
        background,
        ...containerStyle,
      }}
    >
      <TextField
        {...otherProps}
        // type={number ? "number" : ""}
        placeholder={placeholder || ""}
        classes={Object.assign({}, commonClasses, classes)}
        onBlur={(e) => {
          if (blurSideEffectOnChange) {
            onChange({ target: { value: _.trim(e.target.value) } });
          }
          onBlur && onBlur();
        }}
        color="secondary"
        InputProps={inputProps}
        disabled={disabled}
        value={value}
        onChange={onChange}
        multiline={multiline}
      />
      {max && (
        <span style={{ position: "absolute", right: 0, color: "#383538" }}>
          {_.get(value, "length", 0)}/{max}
        </span>
      )}
    </div>
  );
}

CustomOutlinedInput.defaultProps = {
  fullWidth: true,
};
