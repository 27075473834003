import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledErrorPageContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.customColors.grey[900],
    height: `calc(100vh - 48px)`,
    color: theme.customColors.white,
  };
});

export const StyledExpiredImg = styled("img", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "block",
    margin: "0 auto",
    marginBottom: "26px",
  };
});

export const StyledExpiredTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontSize: "30px",
    padding: 20,
    fontWeight: theme.fontWeight.BOLD,
  };
});
