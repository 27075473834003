import React, { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Drawer, Tabs, IconButton, Hidden } from "@material-ui/core";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    border: "1px solid #a5a5a5",
    width: drawerWidth,
  },
  currency: {
    fontWeight: 400,
    fontSize: 16,
    paddingLeft: 10,
    minWidth: 150,
    textAlign: "left",
    verticalAlign: "middle",
    cursor: "pointer",
  },
}));
export default function SideMenu({ content, anchor = "left" }) {
  const [leftOpen, setLeftOpen] = React.useState(false);
  const theme = useTheme();
  const leftToggle = () => {
    setLeftOpen(!leftOpen);
  };
  const classes = useStyles();
  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden lgUp implementation="css">
        <IconButton
          onClick={leftToggle}
          style={{
            position: "fixed",
            zIndex: 1000,
            [anchor]: "0",
            top: "50%",
            color: "white",
            background: "#383538",
          }}
        >
          <MenuOpenIcon />
        </IconButton>
        <Drawer
          container={container}
          variant="temporary"
          anchor={anchor}
          open={leftOpen}
          onClose={leftToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.toolbar} />
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          anchor={anchor}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar} />
          {content}
        </Drawer>
      </Hidden>
    </nav>
  );
}
