const appStyle = (theme) => ({
  page: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    zIndex: 1,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  content: {
    flexGrow: 1,
  },
  children: {},
});

export default appStyle;
