import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ListItem, ListItemSecondaryAction } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
const useStyles = makeStyles((theme) => ({
  listItems: {
    color: `${theme.palette.text.primary}`,
    marginLeft: 0,
    fontSize: 13,
    fontWeight: 500,
    // marginTop: 10,
    padding: 10,
    margin: "0px",
    "&.Mui-selected": {
      color: `#555555`,
    },
    "&:hover": { backgroundColor: `#F3F3F3` },
    "&:focus": {
      backgroundColor: `#F3F3F3`,
      "& > img:first-child": {},
    },
  },
  listHeader: {
    fontSize: 15,
    fontWeight: 500,
    minHeight: 38,
    paddingLeft: 10,
    borderBottom: "1px solid #A5A5A5",
    "&:hover": { backgroundColor: `transparent` },
  },
  nohover: {
    "&:hover": { backgroundColor: `transparent` },
  },
  listContent: {
    fontSize: 13,
    fontWeight: 500,
  },
  subTitle: {
    paddingLeft: 0,
  },
  error: {
    border: "1px solid #FF3296",
  },
  formPage: {
    // backgroundColor: "#E6E6E6",
    // marginTop: 5,
    // marginBottom: 5,
  },
  selected: { backgroundColor: "#F3F3F3" },
  rightSelected: {
    backgroundColor: "#F3F3F3",
    paddingLeft: "-10px",
  },
  disabled: {
    opacity: 0.5,
    // borderBottom: "1px solid #CCCCCC",
  },
}));

export default function CustomListItem({
  label,
  icon,
  onClick,
  header,
  content,
  rightIcon,
  formPage,
  error,
  selected,
  disabled,
  right,
  nohover,
  subTitle,
  ...props
}) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <ListItem
      button={!header && !content && !subTitle}
      onClick={onClick}
      classes={{
        root: `${
          (header || content || subTitle || nohover) && classes.nohover
        } ${classes.listItems} ${header && classes.listHeader} ${
          content && classes.listContent
        } ${formPage && classes.formPage} ${
          selected && (right ? classes.rightSelected : classes.selected)
        } ${error && classes.error} ${disabled && classes.disabled} ${
          subTitle && classes.subTitle
        }`,
      }}
      {...props}
    >
      <div>{icon && icon}</div>
      <div style={{ overflow: "hidden" }}>{label}</div>
      <ListItemSecondaryAction
        className={`row ${disabled && classes.disabled}`}
      >
        {rightIcon && rightIcon}
        {error && (
          <ErrorOutlineIcon
            style={{ color: "#FF3296", verticalAlign: "middle", margin: 5 }}
          />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
