import React, { useEffect, useState } from "react";
import { Grid, Slider } from "@material-ui/core";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { withStyles } from "@material-ui/core/styles";

import _ from "lodash";
const PrettoSlider = withStyles({
  root: {
    color: "#0096FF",
    height: 12,
  },

  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 6,
    width: 2,
    marginTop: 8,
  },
  markLabel: { fontWeight: 700, marginTop: 3 },
})(Slider);

const Linear = ({ questions, index, setValue, answers }) => {
  const setQuestionData = (index, label, value) => {
    let newData = [...(answers || questions)];
    _.set(newData, `[${index}].${label}`, value);
    setValue(newData);
  };

  const q = questions[index];
  if (!answers)
    return (
      <div>
        <div style={{ margin: "30px 0", fontWeight: 900 }}>
          <span style={{ marginRight: 5 }}>From</span>
          <CustomDropdown
            data={[
              { label: "0", value: 0 },
              { label: "1", value: 1 },
            ]}
            value={q.from}
            onSelect={(e) => setQuestionData(index, "from", e.target.value)}
          />
          <span style={{ margin: 5 }}>To</span>

          <CustomDropdown
            data={[
              { label: "2", value: 2 },
              { label: "3", value: 3 },
              { label: "4", value: 4 },
              { label: "5", value: 5 },
              { label: "6", value: 6 },
              { label: "7", value: 7 },
              { label: "8", value: 8 },
              { label: "9", value: 9 },
              { label: "10", value: 10 },
            ]}
            value={q.to}
            onSelect={(e) => setQuestionData(index, "to", e.target.value)}
          />
        </div>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <h3 style={{ marginTop: 0 }}>{q.from}</h3>
          </Grid>
          <Grid item xs={11}>
            <CustomOutlinedInput
              multiline
              sm
              value={q.fromLabel}
              placeholder="From label (optional)"
              onChange={(e) =>
                setQuestionData(index, "fromLabel", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={1}>
            <h3 style={{ marginTop: 0 }}>{q.to}</h3>
          </Grid>
          <Grid item xs={11}>
            <CustomOutlinedInput
              multiline
              sm
              value={q.toLabel}
              placeholder="To label (optional)"
              onChange={(e) =>
                setQuestionData(index, "toLabel", e.target.value)
              }
            />
          </Grid>
        </Grid>
      </div>
    );

  const value = _.get(answers, `[${index}].value`, "");
  if (!value && value !== 0) {
    setQuestionData(index, "value", parseInt((q.from + q.to) / 2), q);
  }
  return (
    <div>
      <PrettoSlider
        valueLabelDisplay="auto"
        value={value}
        getAriaValueText={(value) => {
          return value;
        }}
        onChange={(e, newValue) => setQuestionData(index, "value", newValue, q)}
        step={1}
        marks={_.range(q.from, q.to + 1).map((value, i) => {
          let label = "";
          if (value == q.from) label = q.fromLabel;
          if (value == q.to) label = q.toLabel;
          return { value: value, label };
        })}
        min={q.from}
        max={q.to}
      />
    </div>
  );
};

export default Linear;
