import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  UPDATE_FORM_BEGIN,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_FAILURE,
} from "./constants";
import { apiUrl } from "../../configure";
import _ from "lodash";
import axios from "axios";
import { enqueueSnackbar } from "features/common/redux/actions";
import * as Sentry from "@sentry/react";
export function updateSettings({ isActive, isDeleted, formId }) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_FORM_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        const result = await axios.post(apiUrl + "api/forms/updateSettings", {
          isActive,
          isDeleted,
          formId,
        });
        dispatch({
          type: UPDATE_FORM_SUCCESS,
          data: result.data,
        });
        resolve();
      } catch (err) {
        dispatch({
          type: UPDATE_FORM_FAILURE,
        });
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.message",
              _.get(err, "response.data.error", "ERROR")
            ),
            options: {
              variant: "error",
            },
          })
        );
        Sentry.captureException(err);
      }
    });

    return promise;
  };
}

export function useUpdateSettings() {
  const dispatch = useDispatch();

  const { updateSettingsPending } = useSelector(
    (state) => ({
      updateSettingsPending: state.form.updateSettingsPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(updateSettings(data));
    },
    [dispatch]
  );

  return {
    updateSettings: boundAction,
    updateSettingsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_FORM_BEGIN:
      return {
        ...state,
        updateSettingsPending: true,
      };

    case UPDATE_FORM_SUCCESS:
      return {
        ...state,
        updateSettingsPending: false,
      };

    case UPDATE_FORM_FAILURE:
      return {
        ...state,
        updateSettingsPending: false,
      };

    default:
      return state;
  }
}
