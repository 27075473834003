import React, { useEffect, useState } from "react";
import axios from "axios";
import useBrowserContextCommunication from "react-window-communication-hook";
import Button from "components/CustomButtons/Button.js";
import _ from "lodash";
import { apiUrl, twitterAuthUrl, discordAuthUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useDispatch } from "react-redux";
import BindingVerify from "./BindingVerify";
import {
  StyledRequireRoleArea,
  StyledStatusText,
  StyledJoinButton,
  StyledJoinedOrTry,
} from "./Bind.styles";
import { ReactComponent as ErrorIcon } from "assets/img/Checkmark.svg";

const RequiredRoles = ({ data, color, statusText }) => {
  return (
    <StyledRequireRoleArea color={color}>
      <div>Required Roles：</div>
      <div>{data.map((item) => item.roleName).join(" / ")}</div>
    </StyledRequireRoleArea>
  );
};

const Bind = ({
  questions,
  index,
  setValue,
  answers,
  checkAgain,
  settings,
  renderErrorMessages,
}) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorCountdown, setErrorCountdown] = useState(0);
  const [statusText, setStatusText] = useState("");
  const [inviteWindowOpen, setInviteWindowOpen] = useState(false);
  const [hasFollowed, setHasFollowed] = useState(false);
  const [communicationState, postMessage] =
    useBrowserContextCommunication("metaform");
  const q = questions[index];
  const isTwitter = q.validation === "Twitter";

  const isRoleVerify = _.get(answers, `[${index}].isRoleVerify`);
  const nameOfAnswer = _.get(answers, `[${index}].value`);

  useEffect(() => {
    const interval = setInterval(() => {
      setErrorCountdown(errorCountdown - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [errorCountdown]);

  useEffect(() => {
    const interval = setInterval(() => {
      setErrorCountdown(errorCountdown - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [errorCountdown]);

  useEffect(() => {
    setLoadingButton(false);
    setStatusText("");
    setInviteWindowOpen(false);
  }, [index]);

  const handleVerify = async (isByJoinWindow = false) => {
    try {
      setLoadingButton(true);
      const result = await axios.post(
        apiUrl + "api/user/follow" + q.validation,
        {
          refreshToken: communicationState.lastMessage.token,
          target: q.content,
          id: communicationState.lastMessage.account.id,
          questionId: q._id,
        }
      );

      // 先查看是否加入
      if (result.data.followed || isTwitter) {
        let discordName =
          communicationState.lastMessage.account.username +
          (communicationState.lastMessage.account.discriminator
            ? "#" + communicationState.lastMessage.account.discriminator
            : "");
        setHasFollowed(true);

        // 審核role是否符合資格
        if (result.data.invalidRoles) {
          setStatusText(
            <>
              <StyledStatusText type="error">
                <ErrorIcon />
                <div className="status-text">
                  Your roles did not qualified, please try other
                </div>
              </StyledStatusText>
              <StyledStatusText>
                <img src={require("assets/img/check2.svg").default} />
                <div className="status-text">Join Success</div>
              </StyledStatusText>
            </>
          );
          postMessage({
            ...communicationState.lastMessage,
            token: result.data.token,
          });
        }

        setStatusText(
          <>
            {bindingRole && (
              <StyledStatusText>
                <img src={require("assets/img/check2.svg").default} />
                <div className="status-text">Match Role</div>
              </StyledStatusText>
            )}
            <StyledStatusText>
              <img src={require("assets/img/check2.svg").default} />
              <div className="status-text">Join Success</div>
            </StyledStatusText>
          </>
        );
        setQuestionData(index, { value: discordName, isRoleVerify: true });
        // postMessage({}); // 為何要設為空值
      } else {
        setStatusText(
          <StyledStatusText type="error">
            <ErrorIcon />
            <div className="status-text">Not joined yet</div>
          </StyledStatusText>
        );

        postMessage({
          ...communicationState.lastMessage,
          token: result.data.token,
        });
      }
    } catch (err) {
      console.log("err: ", err);
    } finally {
      setLoadingButton(false);
    }
  };
  const waitForSeconds = (s) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, s * 1000); // 5000 毫秒 = 5 秒
    });
  };
  const handleJoin = async (socialAuths, urlParams) => {
    setLoadingButton(true);
    window.open(
      socialAuths[q.validation].follow + q.content,
      "_blank",
      urlParams
    );
    setInviteWindowOpen(true);
    if (isTwitter) {
      await waitForSeconds(3);
      const name = _.get(communicationState, "lastMessage.account.username");
      if (name) setQuestionData(index, { value: name, isRoleVerify: true });
      setStatusText(
        <StyledStatusText>
          <img src={require("assets/img/check2.svg").default} />
          <div className="status-text">Follow Success</div>
        </StyledStatusText>
      );
    }
  };

  useEffect(() => {
    if (_.get(communicationState, "lastMessage.type") == "close")
      setLoadingButton(false);
  }, [communicationState]);
  // 當invite 視窗打開後 沒有role verify的狀況下 每五秒去打一次verify
  useEffect(() => {
    let intervalId;
    const verifyInterval = () => {
      intervalId = setInterval(() => {
        handleVerify(true);
      }, 8000);
    };
    // 當invite window打開才執行

    if (inviteWindowOpen) {
      // 題目有沒有設定binding role
      if (bindingRole) {
        if (!isRoleVerify) {
          // role驗證過 就不再打api
          verifyInterval();
        }
      } else {
        if (!nameOfAnswer) {
          // isFollow 就不再打api
          verifyInterval();
        }
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [inviteWindowOpen, isRoleVerify, nameOfAnswer, communicationState]);

  // try other Id 要把communicationState中的資料清空
  const handleTryOtherID = () => {
    setLoadingButton(false);
    postMessage({});
    setQuestionData(index, { value: null });
  };

  // 當 discord/twitter login 後 去打verify
  // useEffect(() => {
  //   if (
  //     !_.isEmpty(communicationState.lastMessage) &&
  //     !_.isEmpty(communicationState.lastMessage.account) &&
  //     communicationState.lastMessage.token
  //   ) {
  //     handleVerify();
  //   }
  // }, [_.get(communicationState, "lastMessage.account.id")]);

  const setQuestionData = (index, value) => {
    let newData = [...(answers || questions)];
    _.set(newData, index, value);
    setValue(newData);
  };

  const bindingRole = _.get(q, "discordValidation.bindingRole", false);

  const renderBindButton = () => {
    const socialAuths = {
      Discord: {
        auth: discordAuthUrl,
        follow: "https://discord.com/invite/",
      },

      Twitter: {
        auth: twitterAuthUrl,
        follow: "https://twitter.com/intent/follow?screen_name=",
      },
    };

    let urlParams = `toolbar=no,
    location=no,
    status=no,
    menubar=no,
    scrollbars=yes,
    resizable=yes,
    width=600px,
    height=600px`;

    if (
      !communicationState.lastMessage ||
      communicationState.lastMessage.type !== q.validation
    ) {
      return (
        <>
          {bindingRole && (
            <RequiredRoles
              data={_.get(q, "discordValidation.roles", [])}
              color="#FF3296"
            />
          )}

          <Button
            color="primary"
            onClick={() => {
              window.open(socialAuths[q.validation].auth, "_blank", urlParams);
            }}
            isLoading={loadingButton}
            loadingText="Connecting..."
          >
            <span className="startRow">
              Connect {q.validation}{" "}
              <img
                src={require(`assets/img/w${_.toLower(q.validation)}.svg`)}
                style={{ marginLeft: 5 }}
                className="smIcon"
              />
            </span>
          </Button>
        </>
      );
    } else {
      if (hasFollowed || nameOfAnswer) {
        return (
          <>
            {bindingRole && (
              <RequiredRoles
                data={_.get(q, "discordValidation.roles", [])}
                color={isRoleVerify ? "#0096FF" : "#FF3296"}
                statusText={
                  <StyledStatusText>
                    <img src={require("assets/img/check2.svg").default} />
                    <div className="status-text">Success</div>
                  </StyledStatusText>
                }
              />
            )}

            {statusText && <div>{statusText}</div>}
            {!isRoleVerify && (
              <Button color="primary" onClick={handleTryOtherID}>
                Try Other ID
              </Button>
            )}
          </>
        );
      } else {
        return (
          <StyledJoinedOrTry>
            {bindingRole && (
              <RequiredRoles
                data={_.get(q, "discordValidation.roles", [])}
                color="#FF3296"
                statusText={statusText}
              />
            )}
            {statusText && <div>{statusText}</div>}
            <StyledJoinButton
              isLoading={loadingButton}
              loadingText="Connecting..."
              color="secondary"
              onClick={() => handleJoin(socialAuths, urlParams)}
            >
              <span>{q.validation == "Discord" ? "Join now" : "Follow"}</span>
              {q.validation == "Discord" && (
                <img
                  src={require("assets/img/discord_b.svg").default}
                  style={{ marginLeft: 8 }}
                  className="smIcon"
                />
              )}
            </StyledJoinButton>
            {!loadingButton && !isTwitter && (
              <>
                <div className="or">or</div>
                <div className="tryBtn">
                  <Button color="primary" onClick={handleTryOtherID}>
                    Try Other ID
                  </Button>
                </div>
              </>
            )}
            <br />
          </StyledJoinedOrTry>
        );
      }
    }
  };
  if (settings) {
    return (
      <div>
        <BindingVerify
          questions={questions}
          index={index}
          setValue={setQuestionData}
          checkAgain={checkAgain}
        />
        {renderErrorMessages("bind")}
      </div>
    );
  }
  if (!answers)
    return (
      <div style={{ marginTop: 40 }}>
        {bindingRole && (
          <RequiredRoles data={_.get(q, "discordValidation.roles", [])} />
        )}
        <Button color="primary">
          <span className="startRow">
            Connect {_.get(q, "validation", "")}{" "}
            <img
              src={require(`assets/img/w${_.toLower(q.validation)}.svg`)}
              style={{ marginLeft: 5 }}
              className="smIcon"
            />
          </span>
        </Button>
      </div>
    );

  return renderBindButton();
};

export default Bind;
